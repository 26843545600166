import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Paper,
  Alert,
  Snackbar,
  CircularProgress,
  TextField,
  Button,
  Divider,
  Badge,
  Tooltip,
} from "@mui/material";
import {
  DataGridPro,
  getGridDateOperators,
  getGridNumericOperators,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import {
  PhoneCall,
  CheckCircle,
  Users,
  Timer,
  Calendar,
  Info,
} from "lucide-react";
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip as RechartsTooltip, 
  Legend, 
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CustomNoRowsOverlay from "../../../general/layout/CustomNoRowsOverlay";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";
import debounce from "lodash.debounce";
import { format, parseISO, subDays } from "date-fns";

// Set MUI X license
LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

// Define status colors
const statusColors = {
  completed: "success",
  failed: "error",
  initiated: "warning",
  pending: "info",
};

// Define chart colors
const CHART_COLORS = [
  "#2196f3", "#4caf50", "#f44336", "#ff9800", 
  "#9c27b0", "#3f51b5", "#009688", "#607d8b",
  "#00bcd4", "#757575",
];

export default function VoiceAiDashboard() {
  const currentUser = decode();
  const navigate = useNavigate();
  const location = useLocation();
  const [profile, setProfile] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [callHistory, setCallHistory] = useState({ calls: [], stats: {}, timeseries: [] });
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const [sortModel, setSortModel] = useState([
    { field: "created_time", sort: "desc" }
  ]);
  const [filterModel, setFilterModel] = useState({});
  const [timeInterval, setTimeInterval] = useState("day");
  const [timeRange, setTimeRange] = useState({
    start_date: subDays(new Date(), 30),
    end_date: new Date(),
  });

  // We will parse URL parameters once on mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // Parse pagination
    const page = parseInt(searchParams.get("page"), 10) || 0;
    const pageSize = parseInt(searchParams.get("pageSize"), 10) || 15;
    
    // Ensure pageSize is valid and in our allowed options
    const validPageSizes = [15, 25, 50, 100];
    const validPageSize = validPageSizes.includes(pageSize) ? pageSize : 15;
    
    setPaginationModel({ page, pageSize: validPageSize });

    // Parse filters
    const filtersString = searchParams.get("filters");
    if (filtersString) {
      try {
        const filters = JSON.parse(filtersString);
        setFilterModel(filters);
      } catch (error) {
        console.error("Error parsing filters from URL:", error);
      }
    }

    // Parse sorting
    const sortString = searchParams.get("sort");
    if (sortString) {
      try {
        const sort = JSON.parse(sortString);
        setSortModel(sort);
      } catch (error) {
        console.error("Error parsing sort from URL:", error);
      }
    }

    // Parse time interval
    const interval = searchParams.get("interval");
    if (interval && ["hour", "day", "week", "month"].includes(interval)) {
      setTimeInterval(interval);
    }

    // Parse date range
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    
    if (startDate && endDate) {
      try {
        const start = new Date(startDate);
        const end = new Date(endDate);
        
        if (!isNaN(start.getTime()) && !isNaN(end.getTime())) {
          setTimeRange({
            start_date: start,
            end_date: end
          });
        }
      } catch (error) {
        console.error("Error parsing date range from URL:", error);
      }
    }

    // Parse location
    const locationId = searchParams.get("locationId");
    if (locationId) {
      setSelectedLocation(locationId);
    }
  }, []);

  // Update URL with current state
  useEffect(() => {
    const params = new URLSearchParams();

    // Add pagination params
    params.set("page", paginationModel.page.toString());
    params.set("pageSize", paginationModel.pageSize.toString());

    // Add filter params if they exist
    if (Object.keys(filterModel).length > 0 && filterModel.items?.length > 0) {
      params.set("filters", JSON.stringify(filterModel));
    }

    // Add sort params if they exist
    if (sortModel.length > 0) {
      params.set("sort", JSON.stringify(sortModel));
    }

    // Add time interval
    params.set("interval", timeInterval);

    // Add date range
    if (timeRange.start_date && timeRange.end_date) {
      params.set("startDate", timeRange.start_date.toISOString());
      params.set("endDate", timeRange.end_date.toISOString());
    }

    // Add selected location
    if (selectedLocation) {
      params.set("locationId", selectedLocation);
    }

    navigate(`?${params.toString()}`, { replace: true });
  }, [paginationModel, filterModel, sortModel, timeInterval, timeRange, selectedLocation, navigate]);

  // Define date operators for DataGridPro
  const dateOperators = getGridDateOperators().filter((operator) =>
    ["is", "after", "onOrAfter", "before", "onOrBefore"].includes(
      operator.value
    )
  );

  // Define number operators for DataGridPro
  const customNumberOperators = getGridNumericOperators().filter(
    (operator) =>
      operator.value !== "isEmpty" && operator.value !== "isNotEmpty"
  );

  // Fetch user profile data
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await customFetch(
          `/v1/users/${currentUser.tenant_user_id}/profile`
        );
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        setProfile(data);
        
        // Set default selected location to first voice_ai_enabled location or primary location
        if (data.locations?.length > 0) {
          const voiceAiEnabledLocations = data.locations.filter(loc => loc.voice_ai_enabled);
          if (voiceAiEnabledLocations.length > 0) {
            setSelectedLocation(voiceAiEnabledLocations[0].id);
          } else if (data.primary_location_id) {
            setSelectedLocation(data.primary_location_id);
          } else {
            setSelectedLocation(data.locations[0].id);
          }
        }
      } catch (error) {
        console.error("Failed to fetch profile:", error);
        showSnackbar("Failed to load profile information", "error");
      }
    };

    fetchProfile();
  }, [currentUser.tenant_user_id]);

  // Handle location change
  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  // Handle filter model change
  const onFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
  };

  // Handle sort model change
  const onSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  // Handle time interval change
  const handleTimeIntervalChange = (event) => {
    const newTimeInterval = event.target.value;
    setTimeInterval(newTimeInterval);
    
    // If changing to hourly interval, check if date range exceeds 7 days
    if (newTimeInterval === 'hour') {
      const startDate = new Date(timeRange.start_date);
      const endDate = new Date(timeRange.end_date);
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
      // If the difference is more than 7 days, adjust the start date
      if (diffDays > 7) {
        const newStartDate = new Date(endDate);
        newStartDate.setDate(endDate.getDate() - 7);
        
        setTimeRange({
          start_date: newStartDate,
          end_date: endDate
        });
      }
    }
  };

  // Debounced fetch call history
  const debouncedFetchCallHistory = useCallback(
    debounce(async () => {
      if (!selectedLocation) return;
      
      setLoading(true);
      
      const params = new URLSearchParams({
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        start_date: timeRange.start_date.toISOString(),
        end_date: timeRange.end_date.toISOString(),
        interval: timeInterval,
        location_id: selectedLocation,
      });

      // Add sort parameters
      if (sortModel[0]?.field && sortModel[0]?.sort) {
        params.append("sortField", sortModel[0].field);
        params.append("sortOrder", sortModel[0].sort);
      }

      // Add filter parameters
      if (filterModel?.items?.length) {
        filterModel.items.forEach((filter) => {
          if (filter.value !== undefined) {
            params.append(`filter_${filter.field}_value`, filter.value);
            params.append(
              `filter_${filter.field}_operator`,
              filter.operator || "equals"
            );
          }
        });
      }

      try {
        const response = await customFetch(
          `/v1/voice-ai/call-history?${params.toString()}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Received pagination data:", data.pagination);
        console.log("Current page:", paginationModel.page);
        
        // Process dates for call history data
        const processedCalls = data.calls.map(call => ({
          ...call,
          created_time: call.created_time ? new Date(call.created_time) : null,
          call_start_time: call.call_start_time ? new Date(call.call_start_time) : null,
          call_end_time: call.call_end_time ? new Date(call.call_end_time) : null,
          updated_time: call.updated_time ? new Date(call.updated_time) : null,
        }));
        
        // Process timeseries data for charts
        const processedTimeseries = data.timeseries.map(item => ({
          ...item,
          time_bucket: format(parseISO(item.time_bucket), 
            timeInterval === 'hour' ? 'MMM d, HH:mm' : 
            timeInterval === 'day' ? 'MMM d' : 
            timeInterval === 'week' ? 'MMM d' : 'MMM yyyy'
          ),
        }));
        
        // Ensure we're setting the state with the latest data
        setCallHistory(prevState => ({
          calls: processedCalls,
          stats: data.stats,
          pagination: data.pagination,
          timeseries: processedTimeseries,
        }));
      } catch (error) {
        console.error("Failed to fetch call history:", error);
        showSnackbar("Failed to load call history data", "error");
      } finally {
        setLoading(false);
      }
    }, 300),
    [paginationModel, filterModel, sortModel, selectedLocation, timeInterval, timeRange]
  );

  // Fetch call history when dependencies change
  useEffect(() => {
    if (selectedLocation) {
      debouncedFetchCallHistory();
    }
    // Cleanup function to cancel pending debounced calls
    return () => debouncedFetchCallHistory.cancel();
  }, [debouncedFetchCallHistory]);

  // Show snackbar message
  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
  };

  // Handle close snackbar
  const handleCloseSnackbar = () => {
    setSnackbarMessage("");
  };

  // Format duration in seconds to minutes and seconds
  const formatDuration = (seconds) => {
    if (!seconds) return "0:00";
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  // DataGrid columns
  const columns = [
    {
      field: "created_time",
      headerName: "Created",
      type: "dateTime",
      minWidth: 170,
      sortable: false,
      filterable: false,
      filterOperators: dateOperators,
      valueGetter: (params, row) => row,
      valueFormatter: (value, row) => {
        if (!row.created_time) return "";
        return format(new Date(row.created_time), "MMM d, yyyy h:mm a");
      },
    },
    {
      field: "contact_name",
      headerName: "Contact Name",
      minWidth: 180,
    },
    {
      field: "contact_phone",
      headerName: "Phone",
      minWidth: 150,
    },
    {
      field: "call_status",
      headerName: "Status",
      minWidth: 120,
      type: "singleSelect",
      valueOptions: [
        'completed', 'failed', 'queue', 'paused', 'initiated', 'no-answer', 'busy', 'hangup_on_voicemail'
      ],
      renderCell: (params) => (
        <Chip
          label={params.value || "Unknown"}
          color={statusColors[params.value] || "default"}
          size="small"
        />
      ),
    },
    {
      field: "call_duration",
      headerName: "Duration",
      minWidth: 120,
      type: "number",
      filterOperators: customNumberOperators,
      valueFormatter: (value, row) => formatDuration(row.call_duration),
    },
    {
      field: "call_outcome_type",
      headerName: "Outcome",
      minWidth: 150,
      type: "singleSelect",
      valueOptions: [
        'booked', 'not_interested', 'followup', 'disconnected', 'bad_phone', 'voicemail', 'no_answer', 'busy', 'live_transfer'
      ],
      renderCell: (params) => {
        const outcomeColors = {
          booked: "success",
          not_interested: "error",
          followup: "warning",
          voicemail: "info",
          live_transfer: "primary",
        };
        
        return (
          <Chip
            label={params.value ? params.value.replace(/_/g, " ") : "Unknown"}
            color={outcomeColors[params.value] || "default"}
            size="small"
          />
        );
      },
    },
    // {
    //   field: "call_start_time",
    //   headerName: "Start Time",
    //   type: "dateTime",
    //   minWidth: 170,
    //   sortable: false,
    //   filterable: false,
    //   filterOperators: dateOperators,
    //   valueFormatter: (value, row) => {
    //     if (!row.call_start_time) return "";
    //     return format(new Date(row.call_start_time), "MMM d, yyyy h:mm a");
    //   },
    // },
    {
      field: "attempt_count",
      headerName: "Attempt",
      type: "number",
      minWidth: 100,
      filterOperators: customNumberOperators,
    },
    {
      field: "processing_status",
      headerName: "Processing",
      minWidth: 130,
      type: "singleSelect",
      valueOptions: [
        'pending', 'processing', 'completed', 'failed'
      ],
      renderCell: (params) => (
        <Chip
          label={params.value || "Unknown"}
          color={params.value === "pending" ? "warning" : "default"}
          size="small"
        />
      ),
    },
  ];

  // Custom toolbar for DataGridPro
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  // Custom no rows overlay
  function CustomNoRowsOverlay() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          p: 2,
        }}
      >
        <Typography variant="body1" color="text.secondary" gutterBottom>
          No call history data available
        </Typography>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Typography variant="body2" color="text.secondary">
            Try adjusting your filters or date range
          </Typography>
        )}
      </Box>
    );
  }

  // Render loading state
  if (!profile) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  // Check if user has any locations
  const hasLocations = profile.locations && profile.locations.length > 0;
  
  // Check if any locations have voice_ai_enabled
  const hasVoiceAiEnabled = hasLocations && profile.locations.some(loc => loc.voice_ai_enabled);

  // If user has no locations, show message about GHL not being connected
  if (!hasLocations) {
    return (
      <Container maxWidth="xl">
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{ color: "primary.main", mb: 2 }}
        >
          SparkyVoice Call History
        </Typography>
        
        <Paper elevation={3} sx={{ p: 4, textAlign: 'center', my: 4 }}>
          <Typography variant="h5" gutterBottom>
            CRM and SparkyVoice are not enabled on your account
          </Typography>
          <Typography variant="body1" paragraph>
            To use SparkyVoice features, you need to connect your CRM account first.
          </Typography>
          <Typography variant="body1">
            Please reach out to support to get your CRM and SparkyVoice enabled for your account.
          </Typography>
        </Paper>
      </Container>
    );
  }
  
  // If user has locations but none have voice_ai_enabled, show upgrade message
  if (hasLocations && !hasVoiceAiEnabled) {
    return (
      <Container maxWidth="xl">
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{ color: "primary.main", mb: 2 }}
        >
          SparkyVoice Call History
        </Typography>
        
        <Paper elevation={3} sx={{ p: 4, textAlign: 'center', my: 4 }}>
          <Typography variant="h5" gutterBottom>
            Upgrade to SparkyVoice
          </Typography>
          <Typography variant="body1" paragraph>
            Your account is connected to CRM, but you don't have SparkyVoice enabled on any of your locations.
          </Typography>
          <Typography variant="body1">
            Please contact your account manager to upgrade to SparkyVoice and start using our voice AI features.
          </Typography>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{ color: "primary.main", mb: 2 }}
      >
        SparkyVoice Call History
      </Typography>

      {/* Location Selector */}
      <Paper elevation={2} sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="location-select-label">Location</InputLabel>
              <Select
                labelId="location-select-label"
                id="location-select"
                value={selectedLocation || ""}
                label="Location"
                onChange={handleLocationChange}
                disabled={!profile?.locations?.length}
              >
                {profile?.locations?.map((location) => (
                  <MenuItem 
                    key={location.id} 
                    value={location.id}
                    disabled={!location.voice_ai_enabled}
                  >
                    {location.name} {location.id === profile.primary_location_id && "(Primary)"} 
                    {!location.voice_ai_enabled && " (No SparkyVoice Data Found)"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedLocation && !profile.locations.find(loc => loc.id === selectedLocation)?.voice_ai_enabled && (
              <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
                No SparkyVoice data available for this location yet. Contact support to enable SparkyVoice.
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="time-interval-label">Time Interval</InputLabel>
              <Select
                labelId="time-interval-label"
                id="time-interval"
                value={timeInterval}
                label="Time Interval"
                onChange={handleTimeIntervalChange}
              >
                <MenuItem value="hour">Hourly</MenuItem>
                <MenuItem value="day">Daily</MenuItem>
                <MenuItem value="week">Weekly</MenuItem>
                <MenuItem value="month">Monthly</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={timeRange.start_date}
                onChange={(newValue) => {
                  if (timeInterval === 'hour') {
                    // For hourly interval, enforce 7-day limit
                    const newEndDate = new Date(timeRange.end_date);
                    const maxEndDate = new Date(newValue);
                    maxEndDate.setDate(newValue.getDate() + 7);
                    
                    // If current end date is more than 7 days after new start date, adjust it
                    if (newEndDate > maxEndDate) {
                      setTimeRange({
                        start_date: newValue,
                        end_date: maxEndDate
                      });
                    } else {
                      setTimeRange((prevTimeRange) => ({ ...prevTimeRange, start_date: newValue }));
                    }
                  } else {
                    setTimeRange((prevTimeRange) => ({ ...prevTimeRange, start_date: newValue }));
                  }
                }}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                value={timeRange.end_date}
                onChange={(newValue) => {
                  if (timeInterval === 'hour') {
                    // For hourly interval, enforce 7-day limit
                    const newStartDate = new Date(timeRange.start_date);
                    const minStartDate = new Date(newValue);
                    minStartDate.setDate(newValue.getDate() - 7);
                    
                    // If current start date is more than 7 days before new end date, adjust it
                    if (newStartDate < minStartDate) {
                      setTimeRange({
                        start_date: minStartDate,
                        end_date: newValue
                      });
                    } else {
                      setTimeRange((prevTimeRange) => ({ ...prevTimeRange, end_date: newValue }));
                    }
                  } else {
                    setTimeRange((prevTimeRange) => ({ ...prevTimeRange, end_date: newValue }));
                  }
                }}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Paper>

      {!selectedLocation ? (
        <Alert severity="info" sx={{ mb: 3 }}>
          Please select a location to view call history data
        </Alert>
      ) : (
        <>
          {/* Stats Cards */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6} lg={3}>
              <Card sx={{ height: '100%', boxShadow: 3 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" color="text.secondary">Total Calls</Typography>
                    <PhoneCall size={24} color={CHART_COLORS[0]} />
                  </Box>
                  <Typography variant="h3" component="div">
                    {callHistory.stats?.completed || 0}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                    {`Unique contacts: ${callHistory.stats?.unique_contacts || 0}`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                    {`Failed calls: ${callHistory.stats?.failed || 0} | Bad dials: ${(callHistory.stats?.disconnected_count || 0) + (callHistory.stats?.bad_phone_count || 0)}`}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card sx={{ height: '100%', boxShadow: 3 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" color="text.secondary">Pick-up Rate</Typography>
                    <Users size={24} color={CHART_COLORS[1]} />
                  </Box>
                  <Typography variant="h3" component="div">
                    {callHistory.stats?.pick_up_rate ? `${Math.round(callHistory.stats.pick_up_rate)}%` : '0%'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    % of calls with actual conversations
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card sx={{ height: '100%', boxShadow: 3 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" color="text.secondary">Success Rate</Typography>
                    <CheckCircle size={24} color={CHART_COLORS[1]} />
                  </Box>
                  <Typography variant="h3" component="div">
                    {callHistory.stats?.success_rate ? `${Math.round(callHistory.stats.success_rate)}%` : '0%'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    % of conversations resulting in bookings/transfers
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card sx={{ height: '100%', boxShadow: 3 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" color="text.secondary">Conversions</Typography>
                    <Calendar size={24} color={CHART_COLORS[3]} />
                  </Box>
                  <Typography variant="h3" component="div">
                    {(callHistory.stats?.booked_count || 0) + (callHistory.stats?.live_transfer_count || 0)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {`Booked: ${callHistory.stats?.booked_count || 0} | Transfers: ${callHistory.stats?.live_transfer_count || 0}`}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Charts */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            {/* Call Volume Trend */}
            <Grid item xs={12} lg={8}>
              <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                      Call Volume Trend
                    </Typography>
                    <Tooltip title="Shows call volume over time based on the selected time interval">
                      <Info size={16} color="#1976d2" />
                    </Tooltip>
                  </Box>
                  <Box sx={{ height: 300 }}>
                    {callHistory.timeseries?.length > 0 ? (
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                          data={callHistory.timeseries}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis 
                            dataKey="time_bucket" 
                            tick={{ fontSize: 12 }}
                            interval={timeInterval === 'hour' ? 2 : 0}
                            angle={timeInterval === 'hour' ? -45 : 0}
                            textAnchor={timeInterval === 'hour' ? 'end' : 'middle'}
                            height={50}
                          />
                          <YAxis />
                          <RechartsTooltip />
                          <Legend />
                          <Line
                            type="monotone"
                            dataKey="total_calls"
                            name="Total Calls"
                            stroke={CHART_COLORS[0]}
                            activeDot={{ r: 8 }}
                          />
                          <Line
                            type="monotone"
                            dataKey="completed_calls"
                            name="Completed"
                            stroke={CHART_COLORS[1]}
                          />
                          <Line
                            type="monotone"
                            dataKey="failed_calls"
                            name="Failed"
                            stroke={CHART_COLORS[2]}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    ) : (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Typography variant="body1" color="text.secondary">
                          No data available
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            {/* Call Outcomes */}
            <Grid item xs={12} md={6} lg={4}>
              <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                      Total Call Outcomes
                    </Typography>
                    <Tooltip title="Shows the distribution of call outcomes from the selected time period">
                      <Info size={16} color="#1976d2" />
                    </Tooltip>
                  </Box>
                  <Box sx={{ height: 300 }}>
                    {callHistory.timeseries?.length > 0 ? (
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={[
                              { name: 'Booked', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.booked_outcomes) || 0), 0) },
                              { name: 'Not Interested', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.not_interested_outcomes) || 0), 0) },
                              { name: 'Follow Up', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.followup_count) || 0), 0) },
                              { name: 'Disconnected', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.disconnected_count) || 0), 0) },
                              { name: 'Bad Phone', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.bad_phone_count) || 0), 0) },
                              { name: 'Voicemail', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.voicemail_count) || 0), 0) },
                              { name: 'No Answer', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.no_answer_count) || 0), 0) },
                              { name: 'Busy', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.busy_count) || 0), 0) },
                              { name: 'Live Transfer', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.live_transfer_count) || 0), 0) },
                              { name: 'No Outcome', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.null_outcome_count) || 0), 0) },
                              { name: 'Other', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.undefined_count) || 0), 0) },
                            ].filter(item => item.value > 0)}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={70}
                            innerRadius={30}
                            paddingAngle={2}
                            fill="#8884d8"
                            dataKey="value"
                            nameKey="name"
                            label={(entry) => `${entry.value}`}
                          >
                            {[
                              { name: 'Booked', color: CHART_COLORS[0] },
                              { name: 'Not Interested', color: CHART_COLORS[2] },
                              { name: 'Follow Up', color: CHART_COLORS[1] },
                              { name: 'Disconnected', color: CHART_COLORS[3] },
                              { name: 'Bad Phone', color: CHART_COLORS[4] },
                              { name: 'Voicemail', color: CHART_COLORS[5] },
                              { name: 'No Answer', color: CHART_COLORS[6] },
                              { name: 'Busy', color: CHART_COLORS[7] },
                              { name: 'Live Transfer', color: CHART_COLORS[8] },
                              { name: 'No Outcome', color: CHART_COLORS[9] },
                              { name: 'Other', color: CHART_COLORS[0] },
                            ].map((entry) => {
                              const matchingData = [
                                { name: 'Booked', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.booked_outcomes) || 0), 0) },
                                { name: 'Not Interested', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.not_interested_outcomes) || 0), 0) },
                                { name: 'Follow Up', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.followup_count) || 0), 0) },
                                { name: 'Disconnected', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.disconnected_count) || 0), 0) },
                                { name: 'Bad Phone', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.bad_phone_count) || 0), 0) },
                                { name: 'Voicemail', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.voicemail_count) || 0), 0) },
                                { name: 'No Answer', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.no_answer_count) || 0), 0) },
                                { name: 'Busy', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.busy_count) || 0), 0) },
                                { name: 'Live Transfer', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.live_transfer_count) || 0), 0) },
                                { name: 'No Outcome', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.null_outcome_count) || 0), 0) },
                                { name: 'Other', value: callHistory.timeseries.reduce((sum, item) => sum + (parseInt(item.undefined_count) || 0), 0) },
                              ].find(item => item.name === entry.name);
                              
                              return matchingData && matchingData.value > 0 ? (
                                <Cell key={`cell-${entry.name}`} fill={entry.color} />
                              ) : null;
                            })}
                          </Pie>
                          <Legend 
                            layout="horizontal" 
                            verticalAlign="bottom" 
                            align="center"
                            wrapperStyle={{ paddingTop: 10 }}
                            formatter={(value, entry) => {
                              const { payload } = entry;
                              return `${value}: ${payload.value}`;
                            }}
                          />
                          <RechartsTooltip formatter={(value, name) => [value, name]} />
                        </PieChart>
                      </ResponsiveContainer>
                    ) : (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Typography variant="body1" color="text.secondary">
                          No data available
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            {/* Call Status Distribution */}
            <Grid item xs={12}>
              <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                      Call Outcome Distribution
                    </Typography>
                    <Tooltip title="Shows the distribution of call outcomes from the selected time period">
                      <Info size={16} color="#1976d2" />
                    </Tooltip>
                  </Box>
                  <Box sx={{ height: 300 }}>
                    {callHistory.timeseries?.length > 0 ? (
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          data={callHistory.timeseries}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis 
                            dataKey="time_bucket" 
                            tick={{ fontSize: 12 }}
                            interval={timeInterval === 'hour' ? 2 : 0}
                            angle={timeInterval === 'hour' ? -45 : 0}
                            textAnchor={timeInterval === 'hour' ? 'end' : 'middle'}
                            height={50}
                          />
                          <YAxis />
                          <RechartsTooltip />
                          <Legend />
                          <Bar dataKey="booked_outcomes" name="Booked" stackId="a" fill={CHART_COLORS[0]} />
                          <Bar dataKey="not_interested_outcomes" name="Not Interested" stackId="a" fill={CHART_COLORS[2]} />
                          <Bar dataKey="followup_count" name="Follow Up" stackId="a" fill={CHART_COLORS[1]} />
                          <Bar dataKey="disconnected_count" name="Disconnected" stackId="a" fill={CHART_COLORS[3]} />
                          <Bar dataKey="bad_phone_count" name="Bad Phone" stackId="a" fill={CHART_COLORS[4]} />
                          <Bar dataKey="voicemail_count" name="Voicemail" stackId="a" fill={CHART_COLORS[5]} />
                          <Bar dataKey="no_answer_count" name="No Answer" stackId="a" fill={CHART_COLORS[6]} />
                          <Bar dataKey="busy_count" name="Busy" stackId="a" fill={CHART_COLORS[7]} />
                          <Bar dataKey="live_transfer_count" name="Live Transfer" stackId="a" fill={CHART_COLORS[8]} />
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Typography variant="body1" color="text.secondary">
                          No data available
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Data Grid */}
          <Card sx={{ boxShadow: 3, mb: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Call History
              </Typography>
              <Box sx={{ height: 600, width: '100%' }}>
                <DataGridPro
                  rows={callHistory.calls || []}
                  loading={loading}
                  columns={columns}
                  onPaginationModelChange={setPaginationModel}
                  rowCount={callHistory.pagination?.total || 0}
                  paginationMode="server"
                  sortingMode="server"
                  filterMode="server"
                  onSortModelChange={onSortModelChange}
                  onFilterModelChange={onFilterModelChange}
                  paginationModel={paginationModel}
                  pageSizeOptions={[15, 25, 50, 100]}
                  headerFilters
                  slots={{
                    toolbar: CustomToolbar,
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  pagination
                  disableRowSelectionOnClick
                  getRowId={(row) => row.id}
                  sx={{
                    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </>
      )}

      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={snackbarSeverity} onClose={handleCloseSnackbar}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}