import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Box,
  Chip,
  Stack,
  Alert,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { calculatePrice } from "../shared/OrderHelper";

function OrderSummary({ orderDetails }) {
  const priceInfo = calculatePrice(orderDetails, orderDetails.quantity, orderDetails.states);

  return (
    <Grid item xs={12} md={6}>
      <Paper elevation={3} sx={{ p: 3, height: "100%" }}>
        <Typography variant="h5" gutterBottom fontWeight="bold">
          Order Summary
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              {orderDetails.productName}
            </Typography>
            {!orderDetails.tiered_pricing && (
              <Typography mb={1} variant="body2" color="text.secondary">
                Base Price: <Chip label={`$${orderDetails.price.toFixed(2)}`} size="small" />
              </Typography>
            )}
            {priceInfo?.appliedTier && (
              <Typography mb={1} variant="body2" color="text.secondary">
                Price per Lead: <Chip label={`$${priceInfo.pricePerLead.toFixed(2)}`} size="small" color="primary" />
              </Typography>
            )}
            {!priceInfo?.appliedTier && orderDetails.tiered_pricing && (
              <Typography mb={1} variant="body2" color="text.secondary">
                Price per Lead: <Chip label={`$${orderDetails.price.toFixed(2)}`} size="small" />
              </Typography>
            )}
            <Typography variant="body2" color="text.secondary">
              Quantity: <Chip label={orderDetails.quantity} size="small" />
            </Typography>
          </Box>
        </Box>

        {/* Pricing Tier Information */}
        {priceInfo?.appliedTier && (
          <Box sx={{ mb: 2 }}>
            <Alert severity="info" sx={{ mb: 2 }}>
              <Typography variant="body2" fontWeight="medium">
                Applied Pricing Tier:
              </Typography>
              <Typography variant="body2">
                {priceInfo.tierType === 'state_count' && 
                  `${priceInfo.appliedTier.min_states}+ states: $${priceInfo.appliedTier.price_per_lead.toFixed(2)} per lead`}
                {priceInfo.tierType === 'state_group' && (
                  <>
                    {priceInfo.appliedTier.group_name}: ${priceInfo.appliedTier.price_per_lead.toFixed(2)} per lead
                    <Typography variant="caption" display="block" color="text.secondary" sx={{ mt: 0.5 }}>
                      States: {priceInfo.appliedTier.states.includes('*') 
                        ? 'All remaining states' 
                        : priceInfo.appliedTier.states.join(', ')}
                    </Typography>
                  </>
                )}
                {priceInfo.tierType === 'order_quantity' && 
                  `${priceInfo.appliedTier.min_quantity}+ leads: $${priceInfo.appliedTier.price_per_lead.toFixed(2)} per lead`}
              </Typography>
            </Alert>
          </Box>
        )}

        {orderDetails.states?.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
              Selected States:
            </Typography>
            <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
              {orderDetails.states.map((state) => (
                <Chip key={state} label={state} size="small" />
              ))}
            </Stack>
          </Box>
        )}

        <Divider sx={{ my: 2 }} />

        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Typography variant="subtitle1">Subtotal</Typography>
          <Typography variant="subtitle1">
            ${(priceInfo?.totalPrice || (orderDetails.price * orderDetails.quantity)).toFixed(2)}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" fontWeight="bold">
            Total
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            ${(priceInfo?.totalPrice || (orderDetails.price * orderDetails.quantity)).toFixed(2)}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  );
}

export default OrderSummary;
