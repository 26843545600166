import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Divider,
  Box,
  Switch,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const EditAdSetDialog = ({ open, onClose, adSet, onEdit }) => {
  const [formState, setFormState] = useState({
    custom_name: adSet.custom_name || "",
    cost_per_result_threshold: adSet.cost_per_result_threshold?.toString() || "",
    amount_spent_threshold: adSet.amount_spent_threshold?.toString() || "",
    force_enable: adSet.force_enable || false,
    force_disable: adSet.force_disable || false,
    is_automated: adSet.is_automated || false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSubmit = () => {
    const updatedAdSet = {
      custom_name: formState.custom_name.trim() || null,
      cost_per_result_threshold:
        formState.cost_per_result_threshold.trim() !== ""
          ? parseFloat(formState.cost_per_result_threshold)
          : null,
      amount_spent_threshold:
        formState.amount_spent_threshold.trim() !== ""
          ? parseFloat(formState.amount_spent_threshold)
          : null,
      force_enable: formState.force_enable,
      force_disable: formState.force_disable,
      is_automated: formState.is_automated,
    };

    onEdit(adSet.id, updatedAdSet);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Ad Set</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Custom Name"
          name="custom_name"
          value={formState.custom_name}
          onChange={handleChange}
          fullWidth
        />
        
        <Box sx={{ mt: 3, mb: 2 }}>
          <Divider>
            <Typography variant="subtitle1" color="primary">
              Threshold Settings
            </Typography>
          </Divider>
        </Box>
        
        <TextField
          margin="dense"
          label="Cost Per Result Threshold"
          name="cost_per_result_threshold"
          type="number"
          value={formState.cost_per_result_threshold}
          onChange={handleChange}
          fullWidth
          InputProps={{
            startAdornment: <Typography variant="body2" sx={{ mr: 1 }}>$</Typography>,
          }}
          helperText="Ad sets with cost per result above this threshold may be paused by automation"
        />
        <TextField
          margin="dense"
          label="Amount Spent Threshold"
          name="amount_spent_threshold"
          type="number"
          value={formState.amount_spent_threshold}
          onChange={handleChange}
          fullWidth
          InputProps={{
            startAdornment: <Typography variant="body2" sx={{ mr: 1 }}>$</Typography>,
          }}
          helperText="Ad sets that have spent more than this amount may be paused by automation"
        />
        
        <Box sx={{ mt: 3, mb: 2 }}>
          <Divider>
            <Typography variant="subtitle1" color="primary">
              Automation Controls
            </Typography>
          </Divider>
        </Box>
        
        <Tooltip title="Enable or disable automated management of this ad set">
          <FormControlLabel
            control={
              <Switch
                checked={formState.is_automated}
                onChange={handleSwitchChange}
                name="is_automated"
                color="success"
              />
            }
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AutoFixHighIcon color={formState.is_automated ? "success" : "disabled"} sx={{ mr: 1 }} />
                <Typography>
                  {formState.is_automated ? "Automation Enabled" : "Automation Disabled"}
                </Typography>
              </Box>
            }
          />
        </Tooltip>
        
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1, mb: 2 }}>
          When automation is enabled, the system will automatically manage this ad set based on performance thresholds.
        </Typography>
        
        <Box sx={{ mt: 3, mb: 2 }}>
          <Divider>
            <Typography variant="subtitle1" color="primary">
              Manual Override Settings
            </Typography>
          </Divider>
        </Box>
        
        <Typography 
          variant="caption" 
          color="text.secondary" 
          sx={{ display: 'block', mt: 1, mb: 2 }}
        >
          These settings override automated decisions. Use with caution. Requires Automation to be enabled.
        </Typography>
        
        <FormControl fullWidth margin="dense" disabled={!formState.is_automated}>
          <InputLabel>Force Enable</InputLabel>
          <Select
            name="force_enable"
            label="Force Enable"
            value={formState.force_enable}
            onChange={handleChange}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
          <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
            If set to Yes, this ad set will always be enabled regardless of performance.
          </Typography>
        </FormControl>
        
        <FormControl fullWidth margin="dense" disabled={!formState.is_automated}>
          <InputLabel>Force Disable</InputLabel>
          <Select
            name="force_disable"
            label="Force Disable"
            value={formState.force_disable}
            onChange={handleChange}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
          <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
            If set to Yes, this ad set will always be disabled regardless of performance.
          </Typography>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAdSetDialog;
