import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Chip,
  Box,
  FormHelperText,
  IconButton,
  Typography,
  Grid,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFetch } from "./useFetch";
import decode from "../../../general/util/jwtDecode";
import customFetch from "../../../general/auth/customFetch";

const CatalogForm = ({ open, onClose, catalogItem, onSubmit, onSuccess, isEdit, editItemId, partnerId, setError, handleClose }) => {
  const currentUser = decode();
  const [formState, setFormState] = useState({
    name: "",
    tenant_id: "",
    campaign_id: "",
    ad_set_id: "",
    stripe_product_id: null,
    stripe_price_id: null,
    product_type_id: "",
    description: "",
    is_active: true,
    crm_tags: [],
    daily_cap: "",
    order_minimum: "",
    product_key: "",
    allows_replacement: true,
    tiered_pricing: {},
    allows_schedules: false,
  });
  const [tagInput, setTagInput] = useState("");
  const [adSets, setAdSets] = useState([]);
  const [errors, setErrors] = useState({});
  const [tierErrors, setTierErrors] = useState([]);

  const { data: tenants } = useFetch(
    `/v1/partners/${currentUser.partner_id}/tenants`
  );
  const { data: campaigns } = useFetch(
    `/v1/partners/${currentUser.partner_id}/campaigns`
  );
  const { data: stripeProducts } = useFetch(
    `/v1/partners/${currentUser.partner_id}/stripe_products`
  );
  const { data: stripePrices } = useFetch(
    `/v1/partners/${currentUser.partner_id}/stripe_prices`
  );
  const { data: productTypes } = useFetch(`/v1/partners/product-types`);
  const { data: tierTypes } = useFetch(`/v1/partners/product-types/tier-types`);

  useEffect(() => {
    if (catalogItem) {
      setFormState(catalogItem);
    }
  }, [catalogItem]);

  useEffect(() => {
    const fetchAdSets = async () => {
      if (
        formState.campaign_id &&
        (formState.product_key === "tenant-ad-set" ||
          formState.product_key === "agent-adset")
      ) {
        const response = await customFetch(
          `/v1/partners/${currentUser.partner_id}/ad_sets/${formState.campaign_id}`
        );
        if (response.ok) {
          const adSetsData = await response.json();
          setAdSets(adSetsData);
        } else {
          setAdSets([]);
        }
      } else {
        setAdSets([]);
      }
    };

    fetchAdSets();
  }, [formState.campaign_id, formState.product_key, currentUser.partner_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "product_type_id") {
      const selectedProductType = productTypes.find(
        (type) => type.id === value
      );
      setFormState({
        ...formState,
        [name]: value,
        product_key: selectedProductType ? selectedProductType.product_key : "",
        campaign_id: "",
        ad_set_id: "",
      });
    } else if (name === "stripe_product_id") {
      setFormState({
        ...formState,
        [name]: value,
        stripe_price_id: "", // Reset price when product changes
      });
    } else {
      setFormState({ ...formState, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const handleAutocompleteChange = (name, value) => {
    if (name === "campaign_id") {
      // Reset ad_set_id when campaign changes
      setFormState({ ...formState, [name]: value, ad_set_id: "" });
    } else {
      setFormState({ ...formState, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormState({ ...formState, [name]: checked });
  };

  const handleTagAdd = () => {
    if (tagInput && formState.crm_tags.length < 10) {
      setFormState({
        ...formState,
        crm_tags: [...formState.crm_tags, tagInput],
      });
      setTagInput("");
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setFormState({
      ...formState,
      crm_tags: formState.crm_tags.filter((tag) => tag !== tagToDelete),
    });
  };

  const handleTierTypeChange = (e) => {
    const tierType = e.target.value;
    setFormState({
      ...formState,
      tiered_pricing: tierType ? {
        tier_type: tierType,
        tiers: [],
      } : {},
    });
    // Clear tier errors when changing tier type
    setTierErrors([]);
  };

  const addTier = () => {
    const { tier_type, tiers } = formState.tiered_pricing;
    let newTier;

    switch (tier_type) {
      case 'state_count':
        newTier = { min_states: 1, price_per_lead: 0 };
        break;
      case 'state_group':
        newTier = { group_name: '', states: [], price_per_lead: 0 };
        break;
      case 'order_quantity':
        newTier = { min_quantity: 100, price_per_lead: 0 };
        break;
      default:
        return;
    }

    const newTiers = [...tiers, newTier];
    setFormState({
      ...formState,
      tiered_pricing: {
        ...formState.tiered_pricing,
        tiers: newTiers,
      },
    });
    
    // Add an empty error slot for the new tier
    setTierErrors([...tierErrors, '']);
  };

  const updateTier = (index, field, value) => {
    const newTiers = [...formState.tiered_pricing.tiers];
    if (field === 'states' && typeof value === 'string') {
      // Handle state input as comma-separated list
      newTiers[index] = {
        ...newTiers[index],
        [field]: value.split(',').map(s => s.trim().toUpperCase()),
      };
    } else {
      newTiers[index] = {
        ...newTiers[index],
        [field]: value,
      };
    }
    
    // Clear any previous error for this tier when updating
    const newTierErrors = [...tierErrors];
    if (field === 'price_per_lead') {
      // Convert to number with 2 decimal places for validation
      const numValue = parseFloat(parseFloat(value).toFixed(2));
      if (isNaN(numValue) || numValue <= 0) {
        newTierErrors[index] = 'Price must be a positive number';
      } else {
        newTierErrors[index] = '';
      }
      setTierErrors(newTierErrors);
    }
    
    setFormState({
      ...formState,
      tiered_pricing: {
        ...formState.tiered_pricing,
        tiers: newTiers,
      },
    });
  };

  const removeTier = (index) => {
    const newTiers = formState.tiered_pricing.tiers.filter((_, i) => i !== index);
    setFormState({
      ...formState,
      tiered_pricing: {
        ...formState.tiered_pricing,
        tiers: newTiers,
      },
    });
    
    // Remove the error for the removed tier
    const newTierErrors = [...tierErrors];
    newTierErrors.splice(index, 1);
    setTierErrors(newTierErrors);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formState.name) newErrors.name = "Name is required";
    if (!formState.tenant_id) newErrors.tenant_id = "Tenant is required";
    if (!formState.product_type_id)
      newErrors.product_type_id = "Product Type is required";

    if (formState.product_key === "tenant-ad-set" && !formState.ad_set_id) {
      newErrors.ad_set_id = "Ad Set is required for this product type";
    }

    if (
      (formState.product_key === "tenant-campaign-state" ||
        formState.product_key === "tenant-campaign-state-county" ||
        formState.product_key === "tenant-ad-set" ||
        ["agent-lead", "agent-overrun", "agent-adset"].includes(
          formState.product_key
        )) &&
      !formState.campaign_id
    ) {
      newErrors.campaign_id = "Campaign is required for this product type";
    }

    if (
      ["agent-lead", "agent-overrun", "agent-adset"].includes(
        formState.product_key
      )
    ) {
      if (!formState.campaign_id)
        newErrors.campaign_id = "Campaign is required for this product type";
      if (!formState.stripe_product_id)
        newErrors.stripe_product_id = "Stripe Product is required";
      if (!formState.stripe_price_id)
        newErrors.stripe_price_id = "Stripe Price is required";
    }

    // Validate tiered pricing
    const newTierErrors = [];
    let hasTierPriceError = false;
    
    if (formState.tiered_pricing.tiers && formState.tiered_pricing.tiers.length > 0) {
      formState.tiered_pricing.tiers.forEach((tier, index) => {
        // Convert to number with 2 decimal places for validation
        const price = parseFloat(parseFloat(tier.price_per_lead).toFixed(2));
        if (isNaN(price) || price <= 0) {
          newTierErrors[index] = 'Price must be a positive number';
          hasTierPriceError = true;
        } else {
          newTierErrors[index] = '';
        }
      });
    }
    
    setTierErrors(newTierErrors);
    if (hasTierPriceError) {
      newErrors.tiered_pricing = "All tier prices must be positive numbers";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      name: formState.name,
      tenant_id: formState.tenant_id,
      campaign_id: formState.campaign_id || null,
      ad_set_id: formState.ad_set_id || null,
      stripe_product_id: formState.stripe_product_id,
      stripe_price_id: formState.stripe_price_id,
      product_type_id: formState.product_type_id,
      description: formState.description,
      is_active: formState.is_active,
      crm_tags: formState.crm_tags,
      daily_cap: formState.daily_cap || null,
      order_minimum: formState.order_minimum || null,
      allows_replacement: formState.allows_replacement,
      tiered_pricing: formState.tiered_pricing,
      allows_schedules: formState.allows_schedules,
    };

    try {
      let response;
      if (isEdit) {
        response = await customFetch(
          `/v1/partners/${partnerId}/product-catalog/${editItemId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
      } else {
        response = await customFetch(
          `/v1/partners/${partnerId}/product-catalog`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
      }

      if (!response.ok) {
        throw new Error("Failed to save product catalog item");
      }

      onSuccess();
      handleClose();
    } catch (error) {
      console.error("Error saving product catalog item:", error);
      setError(error.message);
    }
  };

  const selectedStripeProduct = stripeProducts?.find(
    (product) => product.id === formState.stripe_product_id
  );
  const filteredStripePrices = stripePrices?.filter(
    (price) => price.product === selectedStripeProduct?.stripe_id
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {catalogItem?.id ? "Edit Catalog Item" : "Add New Catalog Item"}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            value={formState.name}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors.name}
            helperText={errors.name}
          />
          <FormControl
            fullWidth
            margin="dense"
            error={!!errors.product_type_id}
          >
            <InputLabel>Product Type</InputLabel>
            <Select
              name="product_type_id"
              value={formState.product_type_id}
              onChange={handleChange}
              label="Product Type"
              required
            >
              {productTypes &&
                productTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
            </Select>
            {errors.product_type_id && (
              <FormHelperText>{errors.product_type_id}</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth margin="dense" error={!!errors.tenant_id}>
            <InputLabel>Tenant</InputLabel>
            <Select
              name="tenant_id"
              label="Tenant"
              value={formState.tenant_id}
              onChange={handleChange}
              required
            >
              {tenants &&
                tenants.map((tenant) => (
                  <MenuItem key={tenant.id} value={tenant.id}>
                    {tenant.name}
                  </MenuItem>
                ))}
            </Select>
            {errors.tenant_id && (
              <FormHelperText>{errors.tenant_id}</FormHelperText>
            )}
          </FormControl>
          {(formState.product_key === "tenant-campaign-state" ||
            formState.product_key === "tenant-campaign-state-county" ||
            formState.product_key === "tenant-ad-set" ||
            ["agent-lead", "agent-overrun", "agent-adset"].includes(
              formState.product_key
            )) && (
            <FormControl fullWidth margin="dense" error={!!errors.campaign_id}>
              <Autocomplete
                options={campaigns || []}
                getOptionLabel={(campaign) => `${campaign.campaign_name} (ID: ${campaign.id}) (Acct: ...${campaign.account_id.slice(-4)})`}
                value={campaigns?.find(campaign => campaign.id === formState.campaign_id) || null}
                onChange={(event, newValue) => {
                  handleAutocompleteChange("campaign_id", newValue ? newValue.id : "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Campaign"
                    required
                    error={!!errors.campaign_id}
                    helperText={errors.campaign_id}
                  />
                )}
              />
            </FormControl>
          )}
          {(formState.product_key === "tenant-ad-set" ||
            formState.product_key === "agent-adset") && (
            <FormControl fullWidth margin="dense" error={!!errors.ad_set_id}>
              <Autocomplete
                options={adSets || []}
                getOptionLabel={(adSet) => `${adSet.name} (ID: ${adSet.id})`}
                value={adSets?.find(adSet => adSet.id === formState.ad_set_id) || null}
                onChange={(event, newValue) => {
                  handleAutocompleteChange("ad_set_id", newValue ? newValue.id : "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ad Set"
                    required
                    error={!!errors.ad_set_id}
                    helperText={errors.ad_set_id}
                  />
                )}
              />
            </FormControl>
          )}
          {["agent-lead", "agent-overrun", "agent-adset"].includes(
            formState.product_key
          ) && (
            <>
              <FormControl
                fullWidth
                margin="dense"
                error={!!errors.stripe_product_id}
              >
                <InputLabel>Stripe Product</InputLabel>
                <Select
                  name="stripe_product_id"
                  value={formState.stripe_product_id}
                  onChange={handleChange}
                  label="Stripe Product"
                  required
                >
                  {stripeProducts &&
                    stripeProducts.map((product) => (
                      <MenuItem key={product.id} value={product.id}>
                        {product.name} - {product.integration_name}
                      </MenuItem>
                    ))}
                </Select>
                {errors.stripe_product_id && (
                  <FormHelperText>{errors.stripe_product_id}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                margin="dense"
                error={!!errors.stripe_price_id}
              >
                <InputLabel>Stripe Price</InputLabel>
                <Select
                  name="stripe_price_id"
                  value={formState.stripe_price_id}
                  onChange={handleChange}
                  label="Stripe Price"
                  required
                  disabled={!formState.stripe_product_id}
                >
                  {filteredStripePrices &&
                    filteredStripePrices.map((price) => (
                      <MenuItem key={price.id} value={price.id}>
                        {price.currency.toUpperCase()} {price.unit_amount / 100} - {price.integration_name}
                      </MenuItem>
                    ))}
                </Select>
                {errors.stripe_price_id && (
                  <FormHelperText>{errors.stripe_price_id}</FormHelperText>
                )}
              </FormControl>
            </>
          )}
          <TextField
            margin="dense"
            label="Daily Cap"
            name="daily_cap"
            value={formState.daily_cap}
            onChange={handleChange}
            fullWidth
            type="number"
          />
          <TextField
            margin="dense"
            label="Order Minimum"
            name="order_minimum"
            value={formState.order_minimum}
            onChange={handleChange}
            fullWidth
            type="number"
          />
          <TextField
            margin="dense"
            label="Description"
            name="description"
            value={formState.description}
            onChange={handleChange}
            fullWidth
            multiline
          />
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Pricing Tier Type</InputLabel>
              <Select
                value={formState.tiered_pricing.tier_type || ''}
                onChange={handleTierTypeChange}
                label="Pricing Tier Type"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {tierTypes?.map((type) => (
                  <MenuItem key={type.type_key} value={type.type_key}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formState.tiered_pricing.tier_type ? 
                  tierTypes?.find(t => t.type_key === formState.tiered_pricing.tier_type)?.description : 
                  'Select a pricing tier type'}
              </FormHelperText>
            </FormControl>

            {formState.tiered_pricing.tier_type && (
              <Box sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="subtitle1">Pricing Tiers</Typography>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={addTier}
                    variant="outlined"
                    size="small"
                  >
                    Add Tier
                  </Button>
                </Box>
                
                {errors.tiered_pricing && (
                  <FormHelperText error sx={{ mb: 2 }}>
                    {errors.tiered_pricing}
                  </FormHelperText>
                )}
                {formState.tiered_pricing.tiers.map((tier, index) => (
                  <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #ddd', borderRadius: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Typography variant="subtitle2">Tier {index + 1}</Typography>
                      <IconButton onClick={() => removeTier(index)} size="small">
                        <DeleteIcon />
                      </IconButton>
                    </Box>

                    {formState.tiered_pricing.tier_type === 'state_count' && (
                      <>
                        <TextField
                          label="Minimum States"
                          type="number"
                          value={tier.min_states}
                          onChange={(e) => updateTier(index, 'min_states', parseInt(e.target.value))}
                          fullWidth
                          margin="dense"
                        />
                      </>
                    )}

                    {formState.tiered_pricing.tier_type === 'state_group' && (
                      <>
                        <TextField
                          label="Group Name"
                          value={tier.group_name}
                          onChange={(e) => updateTier(index, 'group_name', e.target.value)}
                          fullWidth
                          margin="dense"
                        />
                        <TextField
                          label="States (comma-separated)"
                          value={tier.states.join(', ')}
                          onChange={(e) => updateTier(index, 'states', e.target.value)}
                          fullWidth
                          margin="dense"
                          helperText="Enter state codes separated by commas (e.g., CA, NY, FL) or * for all other states"
                        />
                      </>
                    )}

                    {formState.tiered_pricing.tier_type === 'order_quantity' && (
                      <>
                        <TextField
                          label="Minimum Quantity"
                          type="number"
                          value={tier.min_quantity}
                          onChange={(e) => updateTier(index, 'min_quantity', parseInt(e.target.value))}
                          fullWidth
                          margin="dense"
                        />
                      </>
                    )}

                    <TextField
                      label="Price Per Lead"
                      type="number"
                      value={tier.price_per_lead}
                      onChange={(e) => updateTier(index, 'price_per_lead', parseFloat(e.target.value))}
                      fullWidth
                      margin="dense"
                      InputProps={{
                        startAdornment: '$',
                        inputProps: { 
                          min: "0.01",
                          step: "0.01"
                        }
                      }}
                      error={!!tierErrors[index]}
                      helperText={tierErrors[index]}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              CRM Tags
            </Typography>
            <FormHelperText sx={{ mt: -1, mb: 1 }}>
              Add tags to help organize and filter leads in your CRM system (max 10 tags)
            </FormHelperText>
            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
              <TextField
                label="Add Tag"
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleTagAdd();
                  }
                }}
                helperText="Press Enter or click Add to create a tag"
                sx={{ flex: 1 }}
              />
              <Button
                onClick={handleTagAdd}
                variant="outlined"
                disabled={!tagInput || formState.crm_tags.length >= 10}
                sx={{ height: 56 }}
              >
                Add
              </Button>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {formState.crm_tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleTagDelete(tag)}
                  color="primary"
                  variant="outlined"
                />
              ))}
            </Box>
          </Box>

          <Box sx={{ mt: 3, pt: 2, borderTop: '1px solid #ddd' }}>
            <Typography variant="subtitle1" gutterBottom>
              Additional Settings
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.is_active}
                        onChange={handleCheckboxChange}
                        name="is_active"
                      />
                    }
                    label="Active"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.allows_replacement}
                        onChange={handleCheckboxChange}
                        name="allows_replacement"
                      />
                    }
                    label="Allow Replacements"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.allows_schedules}
                        onChange={handleCheckboxChange}
                        name="allows_schedules"
                      />
                    }
                    label="Allow Schedules"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CatalogForm;
