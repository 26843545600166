import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
  Stack,
  Checkbox,
  Typography,
  Alert,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  statesList
} from "../../../../general/util/statesList";
import customFetch from "../../../../general/auth/customFetch";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { getStripe } from "../../../../general/util/stripe";
import { calculatePrice } from "../shared/OrderHelper";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CreateOrder({
  currentUser,
  typesList,
  previousStates,
}) {
  const [product, setProduct] = useState("");
  const [numLeads, setNumLeads] = useState(30);
  const [dailyCap, setDailyCap] = useState(20);
  const [clientSecret, setClientSecret] = useState(null);
  const [states, setStates] = useState([]);
  const [open, setOpen] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [productDetails, setProductDetails] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [isLoadingStripe, setIsLoadingStripe] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setClientSecret(null);
  };
  const handleShow = () => setOpen(true);

  const handleProductChange = async (event) => {
    const selectedProduct = typesList.find(
      (type) => type.id === event.target.value
    );
    setProduct(selectedProduct.id);
    setProductDetails(selectedProduct);

    // Initialize Stripe when product is selected
    setIsLoadingStripe(true);
    try {
      const stripe = await getStripe(selectedProduct.id);
      setStripePromise(stripe);
    } catch (error) {
      console.error("Failed to initialize Stripe:", error);
    } finally {
      setIsLoadingStripe(false);
    }

    // Apply the minimum order and daily cap based on the selected product
    setNumLeads(selectedProduct.order_minimum || 30);
    setDailyCap(selectedProduct.daily_cap || 10);

    // If the product type is agent-adset, clear and disable the states selection
    if (selectedProduct.product_key === "agent-adset") {
      setStates([]);
    }
  };

  const handleStatesChange = (event, newValues) => {
    setStates(newValues.map(state => state.value));
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleProceedToPayment = async () => {
    console.log('Proceeding to payment with states:', states);
    // Ensure that required fields are filled
    if (!product || !numLeads || !dailyCap) {
      return;
    }

    try {
      const response = await customFetch("/v1/orders/create-checkout-session", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          quantity: numLeads,
          product_id: product,
          daily_cap: dailyCap,
          startDate: null,
          states: productDetails?.product_key === "agent-adset" ? [] : states,
          location_id: selectedLocation || null,
          disclaimer_accepted: disclaimerAccepted,
          disclaimer_user_agent: navigator.userAgent || "Unknown",
        }),
      });

      const data = await response.json();
      setClientSecret(data.clientSecret);
      setProductDetails((prevDetails) => ({
        ...prevDetails,
        orderDetails: data.orderDetails,
      }));
    } catch (error) {
      console.error("Failed to create order:", error);
    }
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await customFetch(
          `/v1/users/${currentUser.tenant_user_id}/profile`
        );
        const data = await response.json();
        if (data.locations && data.locations.length > 0) {
          setLocations(data.locations);
          setSelectedLocation(data.primary_location_id);
        }
      } catch (error) {
        console.error("Failed to fetch locations:", error);
      }
    };

    if (open) {
      fetchLocations();
    }
  }, [open, currentUser.tenant_user_id]);

  useEffect(() => {
    if (open && previousStates.length > 0) {
      setStates(previousStates);
    }
  }, [open, previousStates]);

  // Add helper functions to check tier requirements
  const checkTierRequirements = () => {
    if (!productDetails || !productDetails.tiered_pricing || !productDetails.tiered_pricing.tiers) {
      return true; // No tier requirements to check
    }

    const { tier_type, tiers } = productDetails.tiered_pricing;
    
    switch (tier_type) {
      case 'state_count':
        // Find the minimum states required across all tiers
        const minStatesRequired = Math.min(...tiers.map(tier => tier.min_states));
        return states.length >= minStatesRequired;
        
      case 'order_quantity':
        // Find the minimum quantity required across all tiers
        const minQuantityRequired = Math.min(...tiers.map(tier => tier.min_quantity));
        return numLeads >= minQuantityRequired;
        
      case 'state_group':
        // For state_group, check if selected states are valid based on tier configuration
        if (productDetails?.product_key === "agent-adset") {
          return true; // Not applicable for adset products
        }
        
        // If no states selected, validation fails
        if (states.length === 0) {
          return false;
        }
        
        // Check if all selected states are allowed in the tier configuration
        const hasWildcard = tiers.some(tier => tier.states.includes('*'));
        if (hasWildcard) {
          return true; // All states are allowed if any tier has wildcard
        }
        
        // Get all allowed states from all tiers
        const allowedStates = tiers.flatMap(tier => tier.states);
        // Check if all selected states are in the allowed states list
        return states.every(state => allowedStates.includes(state));
        
      default:
        return true;
    }
  };

  const getTierValidationMessage = () => {
    if (!productDetails || !productDetails.tiered_pricing || !productDetails.tiered_pricing.tiers) {
      return "";
    }

    const { tier_type, tiers } = productDetails.tiered_pricing;
    
    switch (tier_type) {
      case 'state_count':
        const minStatesRequired = Math.min(...tiers.map(tier => tier.min_states));
        if (states.length < minStatesRequired) {
          return `At least ${minStatesRequired} states must be selected based on tier requirements`;
        }
        break;
        
      case 'order_quantity':
        const minQuantityRequired = Math.min(...tiers.map(tier => tier.min_quantity));
        if (numLeads < minQuantityRequired) {
          return `Minimum order quantity of ${minQuantityRequired} is required based on tier requirements`;
        }
        break;
        
      case 'state_group':
        if (productDetails?.product_key !== "agent-adset" && states.length === 0) {
          return "Please select at least one state";
        }
        
        const hasWildcard = tiers.some(tier => tier.states.includes('*'));
        if (!hasWildcard) {
          // Get all allowed states from all tiers
          const allowedStates = tiers.flatMap(tier => tier.states);
          // Check if all selected states are in the allowed states list
          const invalidStates = states.filter(state => !allowedStates.includes(state));
          if (invalidStates.length > 0) {
            return `The following states are not available in the current tier configuration: ${invalidStates.join(', ')}`;
          }
        }
        break;
    }
    
    return "";
  };

  // Get available states based on tier configuration
  const getAvailableStates = () => {
    if (!productDetails || !productDetails.tiered_pricing || 
        !productDetails.tiered_pricing.tiers || 
        productDetails.tiered_pricing.tier_type !== 'state_group') {
      return statesList; // Return all states if not using state_group tier type
    }

    const { tiers } = productDetails.tiered_pricing;
    const hasWildcard = tiers.some(tier => tier.states.includes('*'));
    
    if (hasWildcard) {
      return statesList; // All states are available if wildcard is present
    }
    
    // Get all allowed states from all tiers
    const allowedStates = tiers.flatMap(tier => tier.states);
    // Filter statesList to only include allowed states
    return statesList.filter(state => allowedStates.includes(state.value));
  };

  const isFormValid =
    product &&
    numLeads >= (productDetails?.order_minimum || 30) &&
    dailyCap >= (productDetails?.daily_cap || 10) &&
    (productDetails?.product_key === "agent-adset" || states.length > 0) &&
    checkTierRequirements() &&
    disclaimerAccepted;

  // Create a separate render function for the payment dialog
  const renderPaymentDialog = () => {
    if (!clientSecret || !stripePromise) return null;

    return (
      <Dialog
        open={!!clientSecret}
        onClose={() => setClientSecret(null)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Complete Your Payment</DialogTitle>
        <DialogContent>
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret,
              appearance: {
                theme: "stripe",
                variables: {
                  colorPrimary: "#1976d2", // Match MUI primary color
                },
              },
            }}
          >
            <CheckoutForm
              orderDetails={{
                productName: productDetails?.name,
                quantity: numLeads,
                dailyCap: dailyCap,
                states: states,
                price: productDetails?.price,
                tiered_pricing: productDetails?.tiered_pricing
              }}
              onSuccess={() => {
                // Navigate to order completion page with payment intent ID
                const searchParams = new URLSearchParams({
                  payment_intent: clientSecret.split("_secret_")[0],
                  redirect_status: "succeeded",
                });
                window.location.href = `/order-completion?${searchParams.toString()}`;
              }}
            />
          </Elements>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className="content">
      <Button variant="contained" color="primary" onClick={handleShow}>
        Purchase Leads
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            maxHeight: '90vh',
          }
        }}
      >
        <DialogTitle>Create New Order</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', gap: 3, mt: 2 }}>
            {/* Left Column - Pricing Information */}
            <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="h6" gutterBottom>
                Product Selection
              </Typography>
              <FormControl fullWidth>
                <TextField
                  select
                  label="Product"
                  value={product}
                  onChange={handleProductChange}
                  required
                  SelectProps={{
                    native: false,
                  }}
                  error={!product}
                  helperText={!product ? "Product is required" : ""}
                >
                  {typesList
                    .filter(
                      (type) =>
                        type.product_key === "agent-lead" ||
                        type.product_key === "agent-adset"
                    )
                    .map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>

              {productDetails && (
                <>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6" gutterBottom color="primary">
                        Estimated Price
                      </Typography>
                      {(() => {
                        const priceInfo = calculatePrice(productDetails, numLeads, states);
                        return (
                          <Stack spacing={1}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <Typography variant="body1">Price per lead:</Typography>
                              <Typography variant="h6" color="primary">
                                ${priceInfo.pricePerLead.toFixed(2)}
                              </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <Typography variant="body1">Total estimated price:</Typography>
                              <Typography variant="h6" color="primary">
                                ${priceInfo.totalPrice.toFixed(2)}
                              </Typography>
                            </Box>
                            {priceInfo.tierType && priceInfo.appliedTier && (
                              <Alert severity="info" sx={{ mt: 1 }}>
                                <Typography variant="body2">
                                  {priceInfo.tierType === 'state_count' && 
                                    `Applied tier: ${priceInfo.appliedTier.min_states}+ states`}
                                  {priceInfo.tierType === 'state_group' && 
                                    `Applied tier: ${priceInfo.appliedTier.group_name || 'Custom state group'}`}
                                  {priceInfo.tierType === 'order_quantity' && 
                                    `Applied tier: ${priceInfo.appliedTier.min_quantity}+ leads`}
                                </Typography>
                              </Alert>
                            )}
                          </Stack>
                        );
                      })()}
                    </CardContent>
                  </Card>

                  {/* Pricing Tiers Breakdown */}
                  {productDetails.tiered_pricing && productDetails.tiered_pricing.tiers && productDetails.tiered_pricing.tiers.length > 0 && (
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="h6" gutterBottom color="primary">
                          Available Pricing Tiers
                        </Typography>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  {productDetails.tiered_pricing.tier_type === 'state_count' && 'Number of States'}
                                  {productDetails.tiered_pricing.tier_type === 'state_group' && 'State Group'}
                                  {productDetails.tiered_pricing.tier_type === 'order_quantity' && 'Order Quantity'}
                                </TableCell>
                                <TableCell align="right">Price per Lead</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {productDetails.tiered_pricing.tiers.map((tier, index) => (
                                <TableRow 
                                  key={index}
                                  sx={{
                                    backgroundColor: (() => {
                                      const priceInfo = calculatePrice(productDetails, numLeads, states);
                                      if (priceInfo.appliedTier === tier) {
                                        return 'rgba(25, 118, 210, 0.08)';
                                      }
                                      return 'inherit';
                                    })()
                                  }}
                                >
                                  <TableCell>
                                    {productDetails.tiered_pricing.tier_type === 'state_count' && 
                                      `${tier.min_states}+ states`}
                                    {productDetails.tiered_pricing.tier_type === 'state_group' && (
                                      <Box>
                                        <Typography variant="body2" fontWeight="medium">
                                          {tier.group_name}
                                        </Typography>
                                        <Typography variant="caption" color="text.secondary">
                                          {tier.states.includes('*') 
                                            ? 'All remaining states' 
                                            : tier.states.join(', ')}
                                        </Typography>
                                      </Box>
                                    )}
                                    {productDetails.tiered_pricing.tier_type === 'order_quantity' && 
                                      `${tier.min_quantity}+ leads`}
                                  </TableCell>
                                  <TableCell align="right">
                                    ${tier.price_per_lead.toFixed(2)}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Typography variant="caption" color="text.secondary" sx={{ mt: 2, display: 'block' }}>
                          {productDetails.tiered_pricing.tier_type === 'state_count' && 
                            'Pricing is based on the total number of states in your order'}
                          {productDetails.tiered_pricing.tier_type === 'state_group' && 
                            'Pricing varies based on specific state groups'}
                          {productDetails.tiered_pricing.tier_type === 'order_quantity' && 
                            'Pricing is based on the total number of leads in your order'}
                        </Typography>
                      </CardContent>
                    </Card>
                  )}
                </>
              )}
            </Box>

            {/* Right Column - Order Form */}
            <Box sx={{ width: '60%' }}>
              <Typography variant="h6" gutterBottom>
                Order Details
              </Typography>
              <Stack spacing={3}>
                {/* GHL Location Selection */}
                <FormControl fullWidth>
                  <TextField
                    select
                    label="GHL Delivery Account"
                    value={selectedLocation}
                    onChange={handleLocationChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    {locations.map((location) => (
                      <MenuItem key={location.id} value={location.id}>
                        {location.name}{" "}
                        {location.id === selectedLocation ? "(Primary)" : ""}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

                {/* Number of Leads */}
                <TextField
                  required
                  type="number"
                  label="Number of Leads"
                  value={numLeads}
                  onChange={(e) => setNumLeads(parseInt(e.target.value))}
                  inputProps={{ min: productDetails?.order_minimum || 30 }}
                  helperText={`Minimum order: ${productDetails?.order_minimum || 30} leads`}
                />

                {/* Daily Cap */}
                <TextField
                  required
                  type="number"
                  label="Daily Cap"
                  value={dailyCap}
                  onChange={(e) => setDailyCap(parseInt(e.target.value))}
                  inputProps={{ min: productDetails?.daily_cap || 10 }}
                  helperText={`Minimum daily cap: ${productDetails?.daily_cap || 10} leads`}
                />

                {/* States Selection */}
                {productDetails?.product_key !== "agent-adset" && (
                  <FormControl fullWidth>
                    <Autocomplete
                      multiple
                      options={getAvailableStates()}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.label}
                      onChange={handleStatesChange}
                      value={statesList.filter((state) => states.includes(state.value))}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="States"
                          placeholder="Select states"
                          error={!checkTierRequirements() && productDetails?.tiered_pricing?.tier_type === 'state_group'}
                          helperText={productDetails?.tiered_pricing?.tier_type === 'state_group' && !checkTierRequirements() ? getTierValidationMessage() : ''}
                        />
                      )}
                    />
                  </FormControl>
                )}
              </Stack>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions sx={{ flexDirection: 'column', alignItems: 'flex-end', padding: 2 }}>
          <Box sx={{ width: '100%', mb: 2, border: '1px solid #e0e0e0', borderRadius: 1, p: 2, bgcolor: '#f5f5f5' }}>
            <FormControl required sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Checkbox
                  checked={disclaimerAccepted}
                  onChange={(e) => setDisclaimerAccepted(e.target.checked)}
                  color="primary"
                  sx={{ mt: -0.5 }}
                />
                <Typography variant="body2" color="text.secondary">
                  <strong>I acknowledge that all sales are final.</strong> I understand the lead replacement policy. I waive my right to a refund and to dispute this charge for any reason. By checking this box, I confirm that I understand and agree to this policy.
                </Typography>
              </Box>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!isFormValid && getTierValidationMessage() && (
                <Typography variant="caption" color="error" sx={{ mr: 2 }}>
                  {getTierValidationMessage()}
                </Typography>
              )}
              {!disclaimerAccepted && (
                <Typography variant="caption" color="error" sx={{ mr: 2 }}>
                  You must accept the terms to proceed
                </Typography>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleProceedToPayment}
                disabled={!isFormValid}
              >
                Proceed to Payment
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      {clientSecret && renderPaymentDialog()}
      {isLoadingStripe && (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </div>
  );
}
