import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
  Box,
  Tooltip,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";

export default function ReplaceLead({ lead, product_key, allows_replacement }) {
  const [reason, setReason] = useState(lead.replacement_reason);
  const [info, setInfo] = useState(lead.replacement_info);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const replacementReasons = [
    { label: "Over 85 years old", value: "age" },
    { label: "Phone Number Disconnected", value: "phone" },
    { label: "Out of Requested States", value: "state" },
  ];

  const getButtonColor = () => {
    switch (lead.replacement_status) {
      case "rejected":
        return "error"; // red
      case "approved":
        return "success"; // green
      case "requested":
        return "warning"; // yellow
      default:
        return "primary"; // default color
    }
  };

  const getButtonLabel = () => {
    if (!allows_replacement) return "Replace";
    switch (lead.replacement_status) {
      case "approved":
        return "Approved";
      case "requested":
        return "Requested";
      case "rejected":
        return "Rejected";
      default:
        return "Replace"; // Default label if no status or different status
    }
  };

  const replaceLead = async (e) => {
    e.preventDefault();
    try {
      const body = {
        id: lead.id,
        replacement_status: lead.replacement_status,
        replacement_reason: reason,
        replacement_info: info,
      };
      await customFetch(`/v1/leads/replace-request/${lead.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      window.location = "/leads";
    } catch (error) {
      console.error(error);
    }
  };

  const tooltipTitle = () => {
    const statusText = lead.replacement_status
      ? lead.replacement_status.charAt(0).toUpperCase() +
        lead.replacement_status.slice(1)
      : "Disabled";

    const adminNoteText = lead.replacement_admin_note
      ? ` - Admin Note: ${lead.replacement_admin_note}`
      : "";

    return `Status: ${statusText}${adminNoteText}`;
  };

  return (
    <>
      <Tooltip title={allows_replacement ? "" : "Replacements are disabled for this product"}>
        <span>
          <Button
            variant="contained"
            color={getButtonColor()}
            onClick={handleShow}
            disabled={!allows_replacement || lead.order_status === "free"}
          >
            {lead.order_status === "free" ? "Free Lead" : getButtonLabel()}
          </Button>
        </span>
      </Tooltip>

      <Dialog open={show} onClose={handleClose}>
        <DialogTitle>Request Replacement for {lead.name}?</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Autocomplete
              options={replacementReasons}
              getOptionLabel={(option) => option.label}
              value={replacementReasons.find((r) => r.value === reason)}
              onChange={(event, newValue) =>
                setReason(newValue ? newValue.value : "")
              }
              renderInput={(params) => (
                <TextField {...params} label="Replacement Reason" />
              )}
            />
            <TextField
              label="More Info"
              value={info}
              onChange={(e) => setInfo(e.target.value)}
              type="text"
              multiline
              maxRows={4}
              placeholder="Please provide more information about this replacement..."
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Close
          </Button>
          <Button onClick={replaceLead} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
