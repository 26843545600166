import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField, 
  Button, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  FormControlLabel, 
  Switch, 
  Typography, 
  Divider, 
  Box,
  Alert,
  Snackbar,
  ButtonGroup,
  CircularProgress
} from '@mui/material';
import customFetch from '../../../../general/auth/customFetch';
import decode from "../../../../general/util/jwtDecode";

const EditCampaignDialog = ({ open, onClose, campaign, onEdit }) => {
  const [formState, setFormState] = useState({
    custom_name: campaign.custom_name || '',
    description: campaign.description || '',
    industry: campaign.industry || '',
    niche: campaign.niche || '',
    isactive: campaign.isactive === null ? false : campaign.isactive,
    is_automated: campaign.is_automated || false,
  });
  const [adSetsUpdateStatus, setAdSetsUpdateStatus] = useState({
    loading: false,
    success: false,
    error: null,
    count: 0
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormState({
      ...formState,
      [name]: checked,
    });
  };

  const handleSubmit = () => {
    onEdit(campaign.id, formState);
    onClose();
  };

  const handleUpdateAllAdSets = async (isAutomated) => {
    setAdSetsUpdateStatus({
      loading: true,
      success: false,
      error: null,
      count: 0
    });

    try {
      // Get the partner_id from the JWT token
      const currentUser = decode();
      const partnerId = currentUser?.partner_id;
      
      if (!partnerId) {
        throw new Error('Partner ID not found');
      }

      const response = await customFetch(`/v1/partners/${partnerId}/campaigns/${campaign.id}/ad_sets/automation`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ is_automated: isAutomated }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update ad sets');
      }

      const data = await response.json();
      
      setAdSetsUpdateStatus({
        loading: false,
        success: true,
        error: null,
        count: data.updated_ad_sets.length
      });
      setSnackbarOpen(true);
    } catch (error) {
      setAdSetsUpdateStatus({
        loading: false,
        success: false,
        error: error.message,
        count: 0
      });
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Campaign</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Custom Name"
          name="custom_name"
          value={formState.custom_name}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Description"
          name="description"
          value={formState.description}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Industry"
          name="industry"
          value={formState.industry}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Niche"
          name="niche"
          value={formState.niche}
          onChange={handleChange}
          fullWidth
        />
        <FormControl fullWidth margin="dense">
        <InputLabel>Is Active</InputLabel>
          <Select
            label="Is Active"
            name="isactive"
            value={formState.isactive}
            onChange={handleChange}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>
        
        <Divider sx={{ my: 2 }} />
        
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1" gutterBottom>
            Automation Settings
          </Typography>
        </Box>
        
        <FormControlLabel
          control={
            <Switch
              checked={formState.is_automated}
              onChange={handleSwitchChange}
              name="is_automated"
              color="primary"
            />
          }
          label="Enable Automation"
        />
        {formState.is_automated && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            When enabled, this campaign will be managed automatically based on customer orders.
          </Typography>
        )}

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Apply Automation to All Ad Sets
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            These actions will update all ad sets associated with this campaign.
          </Typography>
          
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button 
              onClick={() => handleUpdateAllAdSets(true)}
              variant="contained"
              sx={{ 
                bgcolor: 'success.main', 
                '&:hover': { bgcolor: 'success.dark' },
                borderRadius: '20px',
                minWidth: '120px'
              }}
              disabled={adSetsUpdateStatus.loading}
              startIcon={adSetsUpdateStatus.loading ? <CircularProgress size={20} /> : null}
            >
              Enable All
            </Button>
            <Button 
              onClick={() => handleUpdateAllAdSets(false)}
              variant="contained"
              sx={{ 
                bgcolor: 'error.main', 
                '&:hover': { bgcolor: 'error.dark' },
                borderRadius: '20px',
                minWidth: '120px'
              }}
              disabled={adSetsUpdateStatus.loading}
              startIcon={adSetsUpdateStatus.loading ? <CircularProgress size={20} /> : null}
            >
              Disable All
            </Button>
          </Box>
          
          {adSetsUpdateStatus.error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {adSetsUpdateStatus.error}
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
      
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={adSetsUpdateStatus.success ? "success" : "error"}
        >
          {adSetsUpdateStatus.success 
            ? `Successfully updated ${adSetsUpdateStatus.count} ad sets` 
            : `Error: ${adSetsUpdateStatus.error}`}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default EditCampaignDialog;
