import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Checkbox,
  FormControlLabel,
  Alert,
  Tooltip,
  Chip,
  Autocomplete,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CopyIcon from "@mui/icons-material/FileCopy";
import decode from "../../../general/util/jwtDecode";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const GhlMarketplaceApps = () => {
  const [apps, setApps] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [openAppDialog, setOpenAppDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    client_id: "",
    client_secret: "",
    is_active: true,
    tenant_ids: [],
  });
  const currentUser = decode();

  useEffect(() => {
    loadApps();
    loadTenants();
  }, []);

  const loadApps = async () => {
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/ghlapp`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch apps");
      }
      const data = await response.json();
      setApps(data);
    } catch (error) {
      console.error("Failed to load apps:", error);
    }
  };

  const loadTenants = async () => {
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/tenants`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch tenants");
      }
      const data = await response.json();
      setTenants(data);
    } catch (error) {
      console.error("Failed to load tenants:", error);
    }
  };

  const handleOpenAppDialog = (app = null) => {
    setEditMode(!!app);
    setSelectedApp(app);
    setFormValues(
      app
        ? {
            name: app.name,
            client_id: app.client_id,
            client_secret: app.client_secret,
            is_active: app.is_active,
            tenant_ids: app.tenant_ids || [],
          }
        : {
            name: "",
            client_id: "",
            client_secret: "",
            is_active: true,
            tenant_ids: [],
          }
    );
    setOpenAppDialog(true);
  };

  const handleCloseAppDialog = () => {
    setOpenAppDialog(false);
    setSelectedApp(null);
    setFormValues({
      name: "",
      client_id: "",
      client_secret: "",
      is_active: true,
      tenant_ids: [],
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleTenantChange = (event, newValue) => {
    setFormValues({
      ...formValues,
      tenant_ids: newValue.map((tenant) => tenant.id),
    });
  };

  const handleCheckboxChange = (e) => {
    setFormValues({ ...formValues, is_active: e.target.checked });
  };

  const handleSubmitApp = async () => {
    try {
      if (editMode && selectedApp) {
        const payload = {
          name: formValues.name,
          is_active: formValues.is_active,
          tenant_ids: formValues.tenant_ids,
        };
        if (formValues.client_id !== selectedApp.client_id) {
          payload.client_id = formValues.client_id;
        }
        if (formValues.client_secret !== selectedApp.client_secret) {
          payload.client_secret = formValues.client_secret;
        }
        const response = await customFetch(
          `/v1/partners/${currentUser.partner_id}/ghlapp/${selectedApp.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to update app");
        }
      } else {
        const response = await customFetch(
          `/v1/partners/${currentUser.partner_id}/ghlapp`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formValues),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to create app");
        }
      }
      loadApps();
      handleCloseAppDialog();
    } catch (error) {
      console.error("Failed to save app:", error);
    }
  };

  const handleDeleteApp = async (id) => {
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/ghlapp/${id}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete app");
      }
      loadApps();
    } catch (error) {
      console.error("Failed to delete app:", error);
    }
  };

  const appColumns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    { field: "name", headerName: "Name", flex: 1.2 },
    { field: "app_id", headerName: "App ID", flex: 1.2 },
    { field: "client_id", headerName: "Client ID", flex: 0.5 },
    { field: "client_secret", headerName: "Client Secret", flex: 0.5 },
    {
      field: "tenants",
      headerName: "Tenants",
      flex: 0.8,
      renderCell: (params) => {
        const tenantCount = (params.row.tenant_ids || []).length;
        const assignedTenants = tenants.filter((t) =>
          params.row.tenant_ids?.includes(t.id)
        );

        return (
          <Tooltip title={
            <div>
              {assignedTenants.map((tenant) => (
                <div key={tenant.id}>{tenant.name}</div>
              ))}
            </div>
          }>
            <Chip
              label={`${tenantCount} ${tenantCount === 1 ? "Tenant" : "Tenants"}`}
              color="primary"
              variant="outlined"
            />
          </Tooltip>
        );
      },
    },
    { field: "is_active", headerName: "Is Active", flex: 0.5, type: "boolean" },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleOpenAppDialog(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteApp(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const activeAppsCount = apps.filter((app) => app.is_active).length;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        GHL Marketplace Apps
      </Typography>
      <Typography variant="body1" gutterBottom>
        A developer will need to create an app{" "}
        <a href="https://marketplace.gohighlevel.com/apps">here</a> and then
        save the client and secret to this page.
      </Typography>
      {activeAppsCount > 1 && (
        <Alert severity="warning">
          There is more than one active marketplace app!
        </Alert>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenAppDialog()}
        sx={{ mb: 2 }}
      >
        Add New App
      </Button>
      <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
        <DataGridPro rows={apps} columns={appColumns} pageSize={5} />
      </div>

      <Dialog open={openAppDialog} onClose={handleCloseAppDialog} maxWidth="md" fullWidth>
        <DialogTitle>{editMode ? "Edit App" : "Add New App"}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            value={formValues.name}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Client ID"
            name="client_id"
            value={formValues.client_id}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Client Secret"
            name="client_secret"
            value={formValues.client_secret}
            onChange={handleChange}
            fullWidth
          />
          <Autocomplete
            multiple
            id="tenants-select"
            options={tenants}
            getOptionLabel={(option) => option.name}
            value={tenants.filter((tenant) =>
              formValues.tenant_ids.includes(tenant.id)
            )}
            onChange={handleTenantChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Tenants"
                placeholder="Select tenants"
                margin="dense"
                fullWidth
              />
            )}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.is_active}
                onChange={handleCheckboxChange}
                name="is_active"
              />
            }
            label="Is Active"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAppDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmitApp} color="primary">
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default GhlMarketplaceApps;
