import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Divider,
  Box,
  Switch,
  FormControlLabel,
  Tooltip,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import customFetch from "../../../../general/auth/customFetch";

const EditCampaignDialog = ({ open, onClose, campaign, onEdit, tenantId }) => {
  const [formState, setFormState] = useState({
    custom_name: campaign.custom_name || "",
    description: campaign.description || "",
    industry: campaign.industry || "",
    niche: campaign.niche || "",
    isactive: campaign.isactive,
    is_automated: campaign.is_automated,
  });

  // State for bulk automation update
  const [loadingAutomation, setLoadingAutomation] = useState(false);
  const [automationError, setAutomationError] = useState(null);
  const [automationSuccess, setAutomationSuccess] = useState(false);
  const [updatedCount, setUpdatedCount] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSubmit = () => {
    const updatedCampaign = {
      custom_name: formState.custom_name.trim() || null,
      description: formState.description.trim() || null,
      industry: formState.industry.trim() || null,
      niche: formState.niche.trim() || null,
      isactive: formState.isactive,
      is_automated: formState.is_automated,
    };

    onEdit(campaign.id, updatedCampaign);
    onClose();
  };

  const handleUpdateAllAdSets = async (automationState) => {
    setLoadingAutomation(true);
    setAutomationError(null);
    setAutomationSuccess(false);
    
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${tenantId}/campaigns/${campaign.id}/ad_sets/automation`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ is_automated: automationState }),
        }
      );
      
      if (!response.ok) {
        throw new Error("Failed to update ad sets automation");
      }
      
      const data = await response.json();
      setUpdatedCount(data.updated_ad_sets.length);
      setAutomationSuccess(true);
      setSnackbarMessage(`Successfully ${automationState ? 'enabled' : 'disabled'} automation for ${data.updated_ad_sets.length} ad sets`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating ad sets automation:", error);
      setAutomationError(error.message || "Failed to update ad sets automation");
    } finally {
      setLoadingAutomation(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Campaign</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Custom Name"
          name="custom_name"
          value={formState.custom_name}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Description"
          name="description"
          value={formState.description}
          onChange={handleChange}
          fullWidth
          multiline
          rows={2}
        />
        
        <Box sx={{ mt: 3, mb: 2 }}>
          <Divider>
            <Typography variant="subtitle1" color="primary">
              Campaign Settings
            </Typography>
          </Divider>
        </Box>
        
        <TextField
          margin="dense"
          label="Industry"
          name="industry"
          value={formState.industry}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Niche"
          name="niche"
          value={formState.niche}
          onChange={handleChange}
          fullWidth
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Status</InputLabel>
          <Select
            name="isactive"
            label="Status"
            value={formState.isactive}
            onChange={handleChange}
          >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </Select>
        </FormControl>
        
        <Box sx={{ mt: 3, mb: 2 }}>
          <Divider>
            <Typography variant="subtitle1" color="primary">
              Automation Settings
            </Typography>
          </Divider>
        </Box>
        
        <Tooltip title="Enable or disable automated management of this campaign">
          <FormControlLabel
            control={
              <Switch
                checked={formState.is_automated}
                onChange={handleSwitchChange}
                name="is_automated"
                color="success"
              />
            }
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AutoFixHighIcon color={formState.is_automated ? "success" : "disabled"} sx={{ mr: 1 }} />
                <Typography>
                  {formState.is_automated ? "Automation Enabled" : "Automation Disabled"}
                </Typography>
              </Box>
            }
          />
        </Tooltip>
        
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1, mb: 2 }}>
          When automation is enabled, the system will automatically manage this campaign based on performance thresholds.
        </Typography>
        
        <Box sx={{ mt: 3, mb: 2 }}>
          <Divider>
            <Typography variant="subtitle1" color="primary">
              Apply Automation to All Ad Sets
            </Typography>
          </Divider>
        </Box>
        
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, my: 2 }}>
          <Button 
            variant="contained" 
            color="success" 
            onClick={() => handleUpdateAllAdSets(true)}
            disabled={loadingAutomation}
            startIcon={loadingAutomation ? <CircularProgress size={20} /> : <AutoFixHighIcon />}
            sx={{ borderRadius: 28 }}
          >
            Enable All
          </Button>
          <Button 
            variant="contained" 
            color="error" 
            onClick={() => handleUpdateAllAdSets(false)}
            disabled={loadingAutomation}
            startIcon={loadingAutomation ? <CircularProgress size={20} /> : null}
            sx={{ borderRadius: 28 }}
          >
            Disable All
          </Button>
        </Box>
        
        {automationError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {automationError}
          </Alert>
        )}
        
        {automationSuccess && (
          <Alert severity="success" sx={{ mt: 2 }}>
            Successfully updated automation for {updatedCount} ad sets.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
      
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Dialog>
  );
};

export default EditCampaignDialog;
