import React, { useState, useEffect } from "react";
import { Container, Typography, Box, IconButton, Chip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EditAdSetDialog from "./components/EditAdSetDialog";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import CustomNoRowsOverlay from "../../../general/layout/CustomNoRowsOverlay";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const AdSetsPage = () => {
  const [adSets, setAdSets] = useState([]);
  const [selectedAdSet, setSelectedAdSet] = useState(null);
  const [open, setOpen] = useState(false);
  const currentUser = decode();

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const fetchAdSets = () => {
    customFetch(`/v1/partners/${currentUser.partner_id}/ad_sets`)
      .then((response) => response.json())
      .then((data) => {
        // Process dates for proper formatting
        const processedData = data.map((item) => ({
          ...item,
          start_time: item.start_time ? new Date(item.start_time) : null,
          end_time: item.end_time ? new Date(item.end_time) : null,
          created_time: item.created_time ? new Date(item.created_time) : null,
          updated_time: item.updated_time ? new Date(item.updated_time) : null,
        }));
        setAdSets(processedData);
      })
      .catch((error) => console.error("Error fetching ad sets:", error));
  };

  useEffect(() => {
    fetchAdSets();
  }, []);

  const handleEditClick = (adSet) => {
    setSelectedAdSet(adSet);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAdSet(null);
  };

  const handleEdit = (id, updatedAdSet) => {
    customFetch(`/v1/partners/${currentUser.partner_id}/ad_sets/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdSet),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(() => {
        fetchAdSets(); // Refetch all ad sets after successful edit
      })
      .catch((error) => console.error("Error updating ad set:", error));
  };

  const formatCurrency = (value, currency = "USD") => {
    if (value == null) return "";
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    }).format(value);
  };

  const formatToLocalTime = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const columns = [
    { field: "id", headerName: "Ad Set ID", width: 75 },
    { field: "ad_set_id", headerName: "FB - AD ID", width: 200 },
    { field: "campaign_id", headerName: "FB - Campaign ID", width: 150 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "custom_name", headerName: "Custom Name", width: 150 },
    { field: "status", headerName: "Status", width: 100 },
    { 
      field: "start_time", 
      headerName: "Start Time", 
      width: 120,
      type: "date",
    },
    { field: "end_time", headerName: "End Time", width: 120 },
    {
      field: "audience_location",
      headerName: "Audience Location",
      width: 250,
      renderCell: (params) => {
        const audienceLocation = params.value;
    
        // Check if 'regions' is defined and not empty
        if (audienceLocation.regions && audienceLocation.regions.length > 0) {
          return (
            <Typography variant="body2">
              {audienceLocation.regions.map((region) => region.name || "N/A").join(", ")}
            </Typography>
          );
        }
    
        // Check if 'medium_geo_areas' is defined and not empty
        if (audienceLocation.medium_geo_areas && audienceLocation.medium_geo_areas.length > 0) {
          return (
            <Typography variant="body2">
              {audienceLocation.medium_geo_areas.map((area) => area.name || "N/A").join(", ")}
            </Typography>
          );
        }
    
        // Default to the first name property found, or 'N/A' if none exists
        const defaultName = (audienceLocation.regions?.[0]?.name) ||
                            (audienceLocation.medium_geo_areas?.[0]?.name) ||
                            "N/A";
    
        return (
          <Typography variant="body2">
            {defaultName}
          </Typography>
        );
      },
    },    
    { field: "results", headerName: "Results", width: 100 },
    { field: "reach", headerName: "Reach", width: 100 },
    { field: "impressions", headerName: "Impressions", width: 100 },
    {
      field: "budget",
      headerName: "Budget",
      width: 100,
      type: "number",
      renderCell: (params) => (
        <Typography variant="body2">
          {formatCurrency(params.value, params.row.currency)}
        </Typography>
      ),
    },
    {
      field: "cost_per_result",
      headerName: "Cost Per Result",
      width: 150,
      type: "number",
      renderCell: (params) => (
        <Typography variant="body2">
          {formatCurrency(params.value, params.row.currency)}
        </Typography>
      ),
    },
    {
      field: "amount_spent",
      headerName: "Amount Spent",
      width: 150,
      type: "number",
      renderCell: (params) => (
        <Typography variant="body2">
          {formatCurrency(params.value, params.row.currency)}
        </Typography>
      ),
    },
    {
      field: "cost_per_result_threshold",
      headerName: "Cost Per Result Threshold",
      width: 200,
      type: "number",
      renderCell: (params) => (
        <Typography variant="body2">
          {formatCurrency(params.value, params.row.currency)}
        </Typography>
      ),
    },
    {
      field: "amount_spent_threshold",
      headerName: "Amount Spent Threshold",
      width: 150,
      type: "number",
      renderCell: (params) => (
        <Typography variant="body2">
          {formatCurrency(params.value, params.row.currency)}
        </Typography>
      ),
    },
    { field: "force_enable", headerName: "Force Enable", width: 100 },
    { field: "force_disable", headerName: "Force Disable", width: 100 },
    {
      field: "is_automated",
      headerName: "Automation",
      width: 130,
      renderCell: (params) => (
        <Chip
          icon={params.value ? <AutoFixHighIcon /> : null}
          label={params.value ? "Enabled" : "Disabled"}
          color={params.value ? "success" : "default"}
          variant={params.value ? "filled" : "outlined"}
          size="small"
        />
      ),
    },
    { 
      field: "created_time", 
      headerName: "Created Time", 
      width: 120,
      type: "date",
    },
    { 
      field: "updated_time", 
      headerName: "Updated Time", 
      width: 200,
      type: "date",
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => handleEditClick(params.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div className="content">
      <div style={{ width: "100%" }}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main",
            marginBottom: 2,
            textAlign: "center",
          }}
        >
          Ad Sets
        </Typography>
        <Box sx={{ height: 600, width: "100%" }}>
          <DataGridPro
            rows={adSets}
            columns={columns}
            getRowId={(row) => row.id}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  name: false,
                  custom_name: false,
                  end_time: false,
                  reach: false,
                  impressions: false,
                  created_time: false,
                  updated_time: false,
                },
              },
            }}
            slots={{
              toolbar: CustomToolbar,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        </Box>
        {selectedAdSet && (
          <EditAdSetDialog
            open={open}
            onClose={handleClose}
            adSet={selectedAdSet}
            onEdit={handleEdit}
          />
        )}
      </div>
    </div>
  );
};

export default AdSetsPage;
