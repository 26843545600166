import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Alert,
  IconButton,
  Paper,
  Grid,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ScheduleIcon from "@mui/icons-material/Schedule";
import customFetch from "../../../../general/auth/customFetch";

const CampaignScheduleDialog = ({ open, onClose, campaign, tenantId, onScheduleUpdated }) => {
  const [schedule, setSchedule] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newSchedule, setNewSchedule] = useState({
    start_time: "09:00",
    end_time: "17:00",
    active_days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    time_zone: "US/Eastern",
  });

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const timezones = [
    "US/Alaska",
    "US/Aleutian",
    "US/Arizona",
    "US/Central",
    "US/East-Indiana",
    "US/Eastern",
    "US/Hawaii",
    "US/Indiana-Starke",
    "US/Michigan",
    "US/Mountain",
    "US/Pacific",
    "US/Samoa"
  ];

  useEffect(() => {
    if (open) {
      fetchSchedule();
    }
  }, [open]);

  const fetchSchedule = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${tenantId}/campaigns/${campaign.id}/schedules`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch schedule");
      }
      const data = await response.json();
      // A campaign can only have one schedule
      setSchedule(data.length > 0 ? data[0] : null);
      setShowAddForm(data.length === 0);
    } catch (error) {
      console.error("Error fetching schedule:", error);
      setError("Failed to load schedule. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleCreateSchedule = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${tenantId}/campaigns/${campaign.id}/schedules`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newSchedule),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to create schedule");
      }
      
      setSuccess("Schedule created successfully");
      setShowAddForm(false);
      setNewSchedule({
        start_time: "09:00",
        end_time: "17:00",
        active_days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        time_zone: "US/Eastern",
      });
      fetchSchedule();
      if (onScheduleUpdated) onScheduleUpdated();
    } catch (error) {
      console.error("Error creating schedule:", error);
      setError("Failed to create schedule. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSchedule = async (scheduleId) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${tenantId}/campaigns/${campaign.id}/schedules/${scheduleId}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete schedule");
      }
      
      setSuccess("Schedule deleted successfully");
      setSchedule(null);
      setShowAddForm(true);
      if (onScheduleUpdated) onScheduleUpdated();
    } catch (error) {
      console.error("Error deleting schedule:", error);
      setError("Failed to delete schedule. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSchedule((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDayChange = (day) => {
    setNewSchedule((prev) => {
      const newDays = prev.active_days.includes(day)
        ? prev.active_days.filter((d) => d !== day)
        : [...prev.active_days, day];
      return {
        ...prev,
        active_days: newDays,
      };
    });
  };

  const formatActiveDays = (days) => {
    if (days.length === 7) return "Every day";
    if (days.length === 0) return "No days selected";
    if (days.length === 5 && 
        days.includes("Monday") && 
        days.includes("Tuesday") && 
        days.includes("Wednesday") && 
        days.includes("Thursday") && 
        days.includes("Friday")) {
      return "Weekdays";
    }
    if (days.length === 2 && 
        days.includes("Saturday") && 
        days.includes("Sunday")) {
      return "Weekends";
    }
    return days.join(", ");
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ScheduleIcon sx={{ mr: 1 }} />
          Campaign Schedule
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>
          {campaign.custom_name || campaign.campaign_name || campaign.name}
        </Typography>
        
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          A campaign can have one active schedule that determines when the campaign will run.
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        
        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {success}
          </Alert>
        )}
        
        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
            <CircularProgress />
          </Box>
        )}
        
        {!loading && (
          <Box sx={{ mb: 3 }}>
            {schedule ? (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  Current Schedule
                </Typography>
                <Paper sx={{ p: 2, mb: 2, position: "relative" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="subtitle2">Time Range:</Typography>
                      <Typography variant="body2">
                        {schedule.start_time} - {schedule.end_time}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="subtitle2">Active Days:</Typography>
                      <Typography variant="body2">
                        {formatActiveDays(schedule.active_days)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography variant="subtitle2">Timezone:</Typography>
                      <Typography variant="body2">{schedule.time_zone}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <Tooltip title="Delete Schedule">
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteSchedule(schedule.id)}
                          sx={{ position: { sm: "absolute" }, top: { sm: 8 }, right: { sm: 8 } }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No schedule is configured for this campaign. Add a schedule below to control when this campaign runs.
              </Typography>
            )}
          </Box>
        )}
        
        {!schedule && !showAddForm && !loading ? (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setShowAddForm(true)}
          >
            Add Schedule
          </Button>
        ) : null}
        
        {showAddForm && (
          <Box sx={{ mt: 3 }}>
            <Divider>
              <Typography variant="subtitle1" color="primary">
                {schedule ? "Edit Schedule" : "Add Schedule"}
              </Typography>
            </Divider>
            
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Start Time"
                  name="start_time"
                  type="time"
                  value={newSchedule.start_time}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="End Time"
                  name="end_time"
                  type="time"
                  value={newSchedule.end_time}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Active Days:
                </Typography>
                <FormGroup row>
                  {daysOfWeek.map((day) => (
                    <FormControlLabel
                      key={day}
                      control={
                        <Checkbox
                          checked={newSchedule.active_days.includes(day)}
                          onChange={() => handleDayChange(day)}
                        />
                      }
                      label={day}
                    />
                  ))}
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Timezone"
                  name="time_zone"
                  value={newSchedule.time_zone}
                  onChange={handleInputChange}
                  fullWidth
                  SelectProps={{
                    native: true,
                  }}
                >
                  {timezones.map((timezone) => (
                    <option key={timezone} value={timezone}>
                      {timezone}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateSchedule}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} /> : "Save Schedule"}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setShowAddForm(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignScheduleDialog;
