import React, { useState, useEffect } from "react";
import CatalogForm from "./CatalogForm";
import { Button, Chip, Tooltip, Box } from "@mui/material";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const CatalogList = () => {
  const currentUser = decode();
  const [catalogItems, setCatalogItems] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [stripeProducts, setStripeProducts] = useState([]);
  const [stripePrices, setStripePrices] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const fetchData = async () => {
    try {
      const [
        catalogItemsResponse,
        tenantsResponse,
        stripeProductsResponse,
        stripePricesResponse,
        campaignsResponse,
      ] = await Promise.all([
        customFetch(`/v1/partners/${currentUser.partner_id}/product-catalog`),
        customFetch(`/v1/partners/${currentUser.partner_id}/tenants`),
        customFetch(`/v1/partners/${currentUser.partner_id}/stripe_products`),
        customFetch(`/v1/partners/${currentUser.partner_id}/stripe_prices`),
        customFetch(`/v1/partners/${currentUser.partner_id}/campaigns`),
      ]);

      if (!catalogItemsResponse.ok)
        throw new Error("Failed to fetch catalog items");
      if (!tenantsResponse.ok) throw new Error("Failed to fetch tenants");
      if (!stripeProductsResponse.ok)
        throw new Error("Failed to fetch stripe products");
      if (!stripePricesResponse.ok)
        throw new Error("Failed to fetch stripe prices");
      if (!campaignsResponse.ok) throw new Error("Failed to fetch campaigns");

      const catalogItemsData = await catalogItemsResponse.json();
      const tenantsData = await tenantsResponse.json();
      const stripeProductsData = await stripeProductsResponse.json();
      const stripePricesData = await stripePricesResponse.json();
      const campaignsData = await campaignsResponse.json();

      setCatalogItems(catalogItemsData);
      setTenants(tenantsData);
      setStripeProducts(stripeProductsData);
      setStripePrices(stripePricesData);
      setCampaigns(campaignsData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentUser.partner_id]);

  const handleCreate = async (item) => {
    const payload = {
      name: item.name,
      tenant_id: item.tenant_id,
      product_type_id: item.product_type_id,
      description: item.description,
      is_active: item.is_active,
      crm_tags: item.crm_tags,
      daily_cap: item.daily_cap,
      order_minimum: item.order_minimum,
      allows_replacement: item.allows_replacement,
      tiered_pricing: item.tiered_pricing || {},
    };

    // Add fields based on product type
    switch (item.product_key) {
      case "tenant-ad-set":
        payload.campaign_id = item.campaign_id;
        payload.ad_set_id = item.ad_set_id;
        break;
      case "tenant-campaign-state":
        payload.campaign_id = item.campaign_id;
        payload.states = item.states;
        break;
      case "tenant-campaign-state-county":
        payload.campaign_id = item.campaign_id;
        break;
      case "agent-lead":
      case "tenant-lead":
      case "agent-overrun":
        payload.campaign_id = item.campaign_id;
        payload.stripe_product_id = item.stripe_product_id;
        payload.stripe_price_id = item.stripe_price_id;
        break;
      case "agent-adset":
        payload.campaign_id = item.campaign_id;
        payload.ad_set_id = item.ad_set_id;
        payload.stripe_product_id = item.stripe_product_id;
        payload.stripe_price_id = item.stripe_price_id;
        break;
    }

    const response = await customFetch(
      `/v1/partners/${currentUser.partner_id}/product-catalog`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    if (response.ok) {
      setEditingItem(null);
      setDialogOpen(false);
      fetchData();
    } else {
      console.error("Failed to create catalog item");
    }
  };

  const handleUpdate = async (item) => {
    const payload = {
      name: item.name,
      tenant_id: item.tenant_id,
      product_type_id: item.product_type_id,
      description: item.description,
      is_active: item.is_active,
      crm_tags: item.crm_tags,
      daily_cap: item.daily_cap,
      order_minimum: item.order_minimum,
      allows_replacement: item.allows_replacement,
      tiered_pricing: item.tiered_pricing || {},
    };

    // Add fields based on product type
    switch (item.product_key) {
      case "tenant-ad-set":
        payload.campaign_id = item.campaign_id;
        payload.ad_set_id = item.ad_set_id;
        break;
      case "tenant-campaign-state":
        payload.campaign_id = item.campaign_id;
        payload.states = item.states;
        break;
      case "tenant-campaign-state-county":
        payload.campaign_id = item.campaign_id;
        break;
      case "agent-lead":
      case "tenant-lead":
      case "agent-overrun":
        payload.campaign_id = item.campaign_id;
        payload.stripe_product_id = item.stripe_product_id;
        payload.stripe_price_id = item.stripe_price_id;
        break;
      case "agent-adset":
        payload.campaign_id = item.campaign_id;
        payload.ad_set_id = item.ad_set_id;
        payload.stripe_product_id = item.stripe_product_id;
        payload.stripe_price_id = item.stripe_price_id;
        break;
    }

    const response = await customFetch(
      `/v1/partners/${currentUser.partner_id}/product-catalog/${item.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    if (response.ok) {
      setEditingItem(null);
      setDialogOpen(false);
      fetchData();
    } else {
      console.error("Failed to update catalog item");
    }
  };

  const handleDelete = async (id) => {
    const response = await customFetch(
      `/v1/partners/${currentUser.partner_id}/product-catalog/${id}`,
      {
        method: "DELETE",
      }
    );

    if (response.ok) {
      // Refresh the catalog items after deletion
      fetchData();
    } else {
      console.error("Failed to delete catalog item");
    }
  };

  const getNameById = (id, data) => {
    if (!id) return "";
    const item = data.find((item) => item.id === id);
    return item ? item.name || item.campaign_name : "";
  };

  const getPriceById = (id) => {
    if (!id) return "";
    const price = stripePrices.find((price) => price.id === id);
    return price ? `$${(price.unit_amount / 100).toFixed(2)}` : "";
  };

  const columns = [
    // Primary Information
    { 
      field: "name", 
      headerName: "Name", 
      flex: 1,
      minWidth: 200,
    },
    {
      field: "tenant_id",
      headerName: "Tenant",
      flex: 0.7,
      minWidth: 150,
      renderCell: (params) => getNameById(params.value, tenants),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      minWidth: 120,
      renderCell: (params) => (
        <Chip 
          label={params.row.is_active ? "Active" : "Inactive"}
          color={params.row.is_active ? "success" : "default"}
          size="small"
        />
      ),
    },

    // Pricing & Replacement
    {
      field: "pricing",
      headerName: "Pricing",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const basePrice = getPriceById(params.row.stripe_price_id);
        const hasTiers = params.row.tiered_pricing && Object.keys(params.row.tiered_pricing).length > 0;
        
        return (
          <Tooltip title={hasTiers ? "Click to view pricing tiers" : basePrice}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {basePrice && <span>{basePrice}</span>}
              {hasTiers && (
                <Chip 
                  label="Tiered" 
                  size="small" 
                  color="primary" 
                  variant="outlined"
                />
              )}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "features",
      headerName: "Features",
      flex: 0.7,
      minWidth: 130,
      renderCell: (params) => (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: 0.5,
          width: '100%',
          height: '100%',
          alignItems: 'flex-start',
          justifyContent: 'center',
          pl: 1
        }}>
          {params.row.allows_replacement && (
            <Chip 
              label="Replacements"
              size="small"
              color="primary"
              variant="outlined"
            />
          )}
          {params.row.allows_schedules && (
            <Chip 
              label="Schedules"
              size="small"
              color="primary"
              variant="outlined"
            />
          )}
          {!params.row.allows_replacement && !params.row.allows_schedules && (
            <span style={{ paddingLeft: 8 }}>—</span>
          )}
        </Box>
      ),
    },

    // Limits
    {
      field: "limits",
      headerName: "Limits",
      flex: 0.8,
      minWidth: 150,
      renderCell: (params) => {
        const limits = [];
        if (params.row.daily_cap) limits.push(`Cap: ${params.row.daily_cap}`);
        if (params.row.order_minimum) limits.push(`Min: ${params.row.order_minimum}`);
        
        return limits.length ? (
          <Tooltip title={limits.join(', ')}>
            <Chip label={limits.length === 2 ? "Cap & Min" : limits[0]} size="small" />
          </Tooltip>
        ) : "—";
      },
    },

    // Tags
    {
      field: "crm_tags",
      headerName: "Tags",
      flex: 0.6,
      minWidth: 100,
      renderCell: (params) => (
        params.value?.length > 0 ? (
          <Tooltip title={params.value.join(", ")}>
            <Chip 
              label={`${params.value.length} tag${params.value.length === 1 ? '' : 's'}`}
              size="small"
              variant="outlined"
            />
          </Tooltip>
        ) : "—"
      ),
    },

    // Technical Details (initially hidden)
    { 
      field: "product_key", 
      headerName: "Product Key", 
      flex: 1,
      minWidth: 150,
      hide: true 
    },
    {
      field: "campaign_id",
      headerName: "Campaign",
      flex: 0.8,
      minWidth: 150,
      hide: true,
      renderCell: (params) => getNameById(params.value, campaigns),
    },
    {
      field: "stripe_product_id",
      headerName: "Stripe Product",
      flex: 1.5,
      minWidth: 200,
      hide: true,
      renderCell: (params) => getNameById(params.value, stripeProducts),
    },

    // Actions
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => {
            setEditingItem(params.row);
            setDialogOpen(true);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDelete(params.row.id)}
        />,
      ],
    },
  ];

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading catalog items: {error.message}</p>;

  const handleCreateSuccess = () => {
    fetchData();
    setError(null);
  };

  const handleEditSuccess = () => {
    fetchData();
    setError(null);
  };

  return (
    <div style={{ height: 600, width: "100%" }}>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        marginBottom: '1rem' 
      }}>
        <h2>Product Catalog</h2>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setEditingItem(null);
            setDialogOpen(true);
          }}
        >
          Add New Catalog Item
        </Button>
      </div>

      <CatalogForm
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setError(null);
        }}
        catalogItem={editingItem}
        isEdit={!!editingItem}
        editItemId={editingItem?.id}
        onSuccess={editingItem ? handleEditSuccess : handleCreateSuccess}
        handleClose={() => setDialogOpen(false)}
        setError={setError}
        partnerId={currentUser.partner_id}
      />
      
      <DataGridPro
        rows={catalogItems}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
        disableSelectionOnClick
        autoHeight
        initialState={{
          columns: {
            columnVisibilityModel: {
              product_key: false,
              campaign_id: false,
              stripe_product_id: false,
            },
          },
        }}
        density="comfortable"
      />
    </div>
  );
};

export default CatalogList;
