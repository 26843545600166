import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Box,
  Chip,
  Stack,
  Divider,
} from "@mui/material";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";

const ProfilePage = () => {
  const [profile, setProfile] = useState(null);
  const [open, setOpen] = useState(false);
  const [primaryLocation, setPrimaryLocation] = useState("");
  const currentUser = decode();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await customFetch(
          `/v1/users/${currentUser.tenant_user_id}/profile`
        );
        const data = await response.json();
        setProfile(data);
        setPrimaryLocation(data.primary_location_id);
      } catch (error) {
        console.error("Failed to fetch profile:", error);
      }
    };

    fetchProfile();
  }, []);

  const handleEdit = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePrimaryLocationChange = async () => {
    // Implement the API call to update the primary location
    // For demonstration, we'll just close the dialog
    setOpen(false);
  };

  if (!profile) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <div className="content">
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main", // Use the primary color from the theme
          marginBottom: 2, // Theme spacing unit
          textAlign: "center", // Center align text
        }}
      >
        Profile
      </Typography>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "100vh",
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={6} sx={{ minWidth: "500px" }}>
            <Card sx={{ boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h5">User Profile</Typography>
                <Divider
                  sx={{
                    my: 2,
                    borderColor: "primary.main",
                    borderWidth: "1px",
                  }}
                />
                <Typography variant="h6">User Information</Typography>
                <Typography variant="body1">
                  Name: {profile.first_name} {profile.last_name}
                </Typography>
                <Typography variant="body1">Email: {profile.email}</Typography>
                <Divider
                  sx={{
                    my: 2,
                    borderColor: "primary.main",
                    borderWidth: "1px",
                  }}
                />
                <Typography variant="h6">Role Information</Typography>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body1">Role:</Typography>
                  <Chip label={`${profile.role_name}`} />
                </Stack>
                <Typography variant="body1">
                  Role Description: {profile.role_description}
                </Typography>
                <Divider
                  sx={{
                    my: 2,
                    borderColor: "primary.main",
                    borderWidth: "1px",
                  }}
                />
                <Typography variant="h6">Assigned Products</Typography>
                {profile.assigned_products ? (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                    {profile.assigned_products.map((product, index) => (
                      <Chip key={index} label={product} />
                    ))}
                  </Box>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No assigned products, please contact your administrator.
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
          {profile.company_name && (
            <Grid item xs={12} md={6} sx={{ minWidth: "500px" }}>
              <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5">Go High Level Status</Typography>
                    <Chip label="Linked" color="primary" />
                  </Box>
                  <Divider
                    sx={{
                      my: 2,
                      borderColor: "primary.main",
                      borderWidth: "1px",
                    }}
                  />
                  <Typography variant="h6">Linked Agency</Typography>
                  <Chip label={profile.company_name} />
                  <Divider
                    sx={{
                      my: 2,
                      borderColor: "primary.main",
                      borderWidth: "1px",
                    }}
                  />
                  <Typography variant="h6">Agency Sub Accounts</Typography>
                  {profile.locations ? (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                      {profile.locations.map((location) => (
                        <Chip
                          key={location.id}
                          label={
                            location.name +
                            (location.id === profile.primary_location_id
                              ? " (Primary)"
                              : "")
                          }
                        />
                      ))}
                    </Box>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      No locations available.
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEdit}
                    sx={{ mt: 2 }}
                  >
                    Edit Primary Sub Account
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Primary Sub Account</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="primary-location-label">
                Primary Sub Account
              </InputLabel>
              {profile.locations ? (
                <Select
                  labelId="primary-location-label"
                  label="Primary Sub Account"
                  value={primaryLocation}
                  onChange={(e) => setPrimaryLocation(e.target.value)}
                >
                  {profile.locations.map((location) => (
                    <MenuItem key={location.id} value={location.id}>
                      {location.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No locations available.
                </Typography>
              )}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handlePrimaryLocationChange} color="primary">
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default ProfilePage;
