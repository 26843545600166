import React, { useState, useEffect } from "react";
import { Typography, Box, Button, IconButton } from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import decode from "../../../general/util/jwtDecode";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import CustomNoRowsOverlay from "../../../general/layout/CustomNoRowsOverlay";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import customFetch from "../../../general/auth/customFetch";
import CreateUser from "./components/CreateUser";
import EditUser from "./components/EditUser";
import DeleteUser from "./components/DeleteUser";
import { GROUP_ADMIN_ROLE_NAME, TENANT_ADMIN_ROLE_NAME } from "../../../general/constants/constants";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function AdminUsers() {
  const currentUser = decode();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [ghlUsers, setGhlUsers] = useState([]);
  const [unassignedGhlUsers, setUnassignedGhlUsers] = useState([]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const fetchAndCombineData = async () => {
    try {
      const ghlResponse = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ghl-users/`
      );
      const ghlJsonData = await ghlResponse.json();
      setGhlUsers(ghlJsonData);

      const userResponse = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/tenant-users/`
      );
      const userJsonData = await userResponse.json();

      const combinedData = userJsonData.map((row) => ({
        ...row,
        full_name: `${row.first_name} ${row.last_name}`,
        ghl_user_display: ghlJsonData.find(
          (ghlUser) => ghlUser.id === row.ghl_user_id
        )
          ? `${
              ghlJsonData.find((ghlUser) => ghlUser.id === row.ghl_user_id)
                .first_name
            } ${
              ghlJsonData.find((ghlUser) => ghlUser.id === row.ghl_user_id)
                .last_name
            } (${
              ghlJsonData.find((ghlUser) => ghlUser.id === row.ghl_user_id)
                .email
            })`
          : "",
      }));

      const assignedGhlUserIds = combinedData
        .filter((row) => row.ghl_user_id !== null)
        .map((row) => row.ghl_user_id);

      const unassignedGhlUsersList = ghlJsonData.filter(
        (ghlUser) => !assignedGhlUserIds.includes(ghlUser.id)
      );

      setUsers(combinedData);
      setUnassignedGhlUsers(unassignedGhlUsersList);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (!currentUser) {
      window.location = "/login";
    } else if (currentUser && (currentUser.role !== TENANT_ADMIN_ROLE_NAME && currentUser.role !== GROUP_ADMIN_ROLE_NAME)) {
      window.location = "/";
    } else {
      fetchAndCombineData();
    }
  }, []);

  const handleEdit = (user) => {
    setSelectedUser(user);
    setOpenEdit(true);
  };

  const handleDelete = (user) => {
    setSelectedUser(user);
    setOpenDelete(true);
  };

  const columns = [
    {
      field: "full_name",
      headerName: "User Name",
      flex: 0.75,
    },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "ghl_user_display",
      headerName: "GHL Linked User",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    { field: "role_name", headerName: "Role", flex: 0.55 },
    {
      field: "crm_coupon",
      headerName: "CRM Coupon",
      flex: 0.55,
      type: "boolean",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "onboarded",
      headerName: "Onboarded",
      flex: 0.55,
      type: "boolean",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "is_active",
      headerName: "Login Access",
      flex: 0.55,
      type: "boolean",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content">
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main", // Use the primary color from the theme
            marginBottom: 2, // Theme spacing unit
            textAlign: "left", // left align text
          }}
        >
          Tenant Users
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 2, // Theme spacing unit
            textAlign: "left", // Center align text
          }}
        >
          Tenant users are users capable of logging into the application. They
          can view and purchase products and can see their delivered leads. If
          they are linked to a GHL agency user, then the leads will also be
          delivered their linked GHL Sub Account.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenCreate(true)}
          sx={{ mb: 2 }}
        >
          Add User
        </Button>
        <Box sx={{ mt: 2 }}>
          <DataGridPro
            rows={users}
            columns={columns}
            pageSize={10}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            headerFilters
            pageSizeOptions={[10, 25, 50]}
            pagination
            slots={{
              toolbar: CustomToolbar,
              noRowsOverlay: CustomNoRowsOverlay,
              headerFilterMenu: null,
            }}
            autoHeight
          />
        </Box>
      </Box>
      <CreateUser
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        refreshUsers={fetchAndCombineData}
        ghlUsers={unassignedGhlUsers} // Pass unassigned GHL users to CreateUser component
      />
      <EditUser
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        user={selectedUser}
        refreshUsers={fetchAndCombineData}
        ghlUsers={ghlUsers} // Pass all GHL users to EditUser component
        unassignedGhlUsers={unassignedGhlUsers} // Pass unassigned GHL users to EditUser component
      />
      <DeleteUser
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        user={selectedUser}
        refreshUsers={fetchAndCombineData}
      />
    </div>
  );
}
