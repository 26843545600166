import React, { useState, useEffect } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Button,
  Box,
  Badge,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import decode from "../util/jwtDecode";
import { Stack } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import customFetch from "../auth/customFetch";
import {
  TENANT_ADMIN_ROLE_NAME,
  PARTNER_ADMIN_ROLE_NAME,
  SUPER_ADMIN_ROLE_NAME,
  GROUP_ADMIN_ROLE_NAME,
} from "../constants/constants";
import { useBranding } from "../../BrandingContext";
import { updateFavicon } from "./faviconUtils";

export default function Layout({
  setAdminMode,
  isAdminMode,
  themeMode,
  setThemeMode,
}) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isTenantAdmin, setTenantAdmin] = useState(false);
  const [isPartnerAdmin, setPartnerAdmin] = useState(false);
  const [isSuperAdmin, setSuperAdmin] = useState(false);
  const [isGroupAdmin, setGroupAdmin] = useState(false);
  const currentUser = decode();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { logoIcon, hasDarkTheme, allLogos, poweredBy, name, description } = useBranding();

  // Add new useEffect for favicon and title management
  useEffect(() => {
    // Update page title
    document.title = description || currentUser.tenant_description || "Lead Generation Software";

    // Update favicon using the light logo icon
    const logoUrl = allLogos.light_logo_icon_url;
    console.log("favicon logo url", logoUrl);
    updateFavicon(logoUrl).catch((error) => {
      console.error("Failed to update favicon:", error);
    });
  }, [currentUser.tenant_description, allLogos.light_logo_icon_url, description]);

  useEffect(() => {
    // Load theme mode from local storage
    const savedThemeMode = localStorage.getItem("themeMode") || "light";
    setThemeMode(savedThemeMode);

    // Check if the current path starts with '/admin' or similar and user is an admin
    if (
      (location.pathname.startsWith("/admin") &&
        currentUser.role === TENANT_ADMIN_ROLE_NAME) ||
      (location.pathname.startsWith("/partner") &&
        currentUser.partner_role === PARTNER_ADMIN_ROLE_NAME) ||
      (location.pathname.startsWith("/super") &&
        currentUser.super_role === SUPER_ADMIN_ROLE_NAME)
    ) {
      if (!isAdminMode) {
        setAdminMode(true);
      }
    } else {
      if (isAdminMode) {
        setAdminMode(false);
      }
    }

    if (currentUser.role === TENANT_ADMIN_ROLE_NAME) {
      setTenantAdmin(true);
    }
    if (currentUser.partner_role === PARTNER_ADMIN_ROLE_NAME) {
      setPartnerAdmin(true);
    }
    if (currentUser.super_role === SUPER_ADMIN_ROLE_NAME) {
      setSuperAdmin(true);
    }
    if (currentUser.role === GROUP_ADMIN_ROLE_NAME) {
      setGroupAdmin(true);
    }
  }, [
    location.pathname,
    isAdminMode,
    setAdminMode,
    currentUser.role,
    isTenantAdmin,
    isPartnerAdmin,
    isSuperAdmin,
    isGroupAdmin,
  ]);

  const handleThemeToggle = () => {
    const newThemeMode = themeMode === "dark" ? "light" : "dark";
    setThemeMode(newThemeMode);
    localStorage.setItem("themeMode", newThemeMode); // Save the theme mode to local storage
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleAdminMode = () => {
    setAdminMode(!isAdminMode);
    if (currentUser.role === TENANT_ADMIN_ROLE_NAME) {
      return navigate(isAdminMode ? "/" : "/admin/");
    }
    if (currentUser.partner_role === PARTNER_ADMIN_ROLE_NAME) {
      return navigate(isAdminMode ? "/" : "/partner/admin/tenants/");
    }
    if (currentUser.super_role === SUPER_ADMIN_ROLE_NAME) {
      return navigate(isAdminMode ? "/" : "/super/admin/partners");
    } else {
      navigate("/");
    }
  };

  const tenantAdminRoutes = [
    { path: "/admin/", label: "Dashboard" },
    { path: "/admin/leads", label: "Leads" },
    { path: "/admin/orders", label: "Orders" },
    { path: "/admin/tenant-overruns", label: "Overrun Orders" },
    { path: "/admin/replacements", label: "Replacements" },
    { path: "/admin/products", label: "Products" },
    { path: "/admin/users", label: "Users" },
    { path: "/admin/user-groups", label: "Groups" },
    { path: "/admin/user-schedules", label: "Schedules" },
    { path: "/admin/user-spreadsheets", label: "Spreadsheets" },
    { path: "/admin/tenant-custom-fields", label: "Custom Fields" },
    { path: "/admin/tenant-mapping-groups", label: "Custom Field Groups" },
    { path: "/admin/ghlusers", label: "GHL Users" },
    { path: "/admin/ghllocations", label: "GHL Accounts" },
    { path: "/admin/integrations", label: "Marketplace Integrations" },
    { path: "/admin/voice-ai", label: "Voice AI" },
    { path: "/admin/ads-management", label: "Ads Management" },
  ];

  const partnerAdminRoutes = [
    { path: "/partner/admin/dashboard", label: "Dashboard" },
    { path: "/partner/admin/tenants", label: "Tenants" },
    { path: "/partner/admin/orders", label: "Orders" },
    { path: "/partner/admin/users", label: "Partner Users" },
    { path: "/partner/admin/tenant-users", label: "Tenant Users" },
    { path: "/partner/admin/campaigns", label: "Campaigns" },
    { path: "/partner/admin/adsets", label: "Ad Sets" },
    { path: "/partner/admin/stripe", label: "Stripe" },
    { path: "/partner/admin/productcatalog", label: "Product Catalog" },
    {
      path: "/partner/admin/ghlmarketplaceapps",
      label: "GHL Marketplace Apps",
    },
    {
      path: "/partner/admin/ghlsharedlocations",
      label: "GHL Shared Locations",
    },
    { path: "/partner/admin/integrations", label: "Integrations" },
    { path: "/partner/admin/audit", label: "Audit Trail" },
  ];

  const superAdminRoutes = [
    { path: "/super/admin/partners", label: "Partners" },
    { path: "/super/admin/users", label: "Super Users" },
  ];

  const userRoutes = [
    { path: "/", label: "Dashboard" },
    { path: "/leads", label: "Delivered Leads" },
    {
      path: "/schedules",
      label: "Lead Delivery Schedules",
    },
    { path: "/orders", label: "Order Management" },
    { path: "/overruns", label: "Overrun Leads" },
    // { path: "/support", label: "Support" },
    { path: "/feedback", label: "Feedback" },
    { path: "/faq", label: "FAQ" },
    { path: "/voice-ai", label: "SparkyVoice™ Dashboard", badge: "New" },
    { path: "/profile", label: "Profile" },
  ];

  const groupAdminRoutes = [
    { path: "/group/admin/leads", label: "Leads" },
    { path: "/group/admin/orders", label: "Orders" },
    { path: "/group/admin/overruns", label: "Overrun Orders" },
    { path: "/group/admin/users", label: "Users" },
    { path: "/group/admin/groups", label: "Groups" },
    { path: "/group/admin/schedules", label: "Schedules" },
    { path: "/group/admin/user-spreadsheets", label: "Spreadsheets" },
    { path: "/group/admin/ghlusers", label: "GHL Users" },
    { path: "/group/admin/ghllocations", label: "GHL Sub Accounts" },
    { path: "/group/admin/integrations", label: "Marketplace Integrations" },
  ];

  const handleLogout = async () => {
    try {
      const body = {
        refresh: document.cookie
          .split(";")
          .find((cookie) => cookie.trim().startsWith("refreshToken="))
          .split("=")[1],
      };
      const logout = await customFetch(`/v1/auth/logout`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (logout.status !== 204) {
        alert("Unable to log out successfully!");
      }

      document.cookie = `accessToken=; path=/; secure; SameSite=Lax; expires: Thu, 01 Jan 1970 00:00:00 UTC;`;
      document.cookie = `refreshToken=; path=/; secure; SameSite=Lax; expires: Thu, 01 Jan 1970 00:00:00 UTC;`;
      window.location = "/login";
    } catch (error) {
      console.error(error);
    }
  };

  const drawer = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <List>
        {!isAdminMode && (
          <>
            {userRoutes.map((route, index) => (
              <ListItemButton
                key={index}
                component={Link}
                to={route.path}
                disabled={route.disabled || false}
                sx={{
                  backgroundColor:
                    location.pathname === route.path
                      ? theme.palette.primary.dark
                      : "inherit",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  color:
                    location.pathname === route.path
                      ? theme.palette.primary.contrastText
                      : "inherit",
                }}
              >
                {route.badge ? (
                  <Badge badgeContent={route.badge} color="secondary">
                    <ListItemText primary={route.label} />
                  </Badge>
                ) : (
                  <ListItemText primary={route.label} />
                )}
              </ListItemButton>
            ))}
          </>
        )}
        {!isAdminMode && isGroupAdmin && (
          <>
            <Typography
              variant="h6"
              sx={{
                paddingLeft: 2,
                paddingTop: 2,
                fontWeight: "bold",
                color: theme.palette.primary.main,
              }}
            >
              Group Admin
            </Typography>
            {groupAdminRoutes.map((route, index) => (
              <ListItemButton
                key={index}
                component={Link}
                to={route.path}
                disabled={route.disabled || false}
                sx={{
                  backgroundColor:
                    location.pathname === route.path
                      ? theme.palette.primary.dark
                      : "inherit",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  color:
                    location.pathname === route.path
                      ? theme.palette.primary.contrastText
                      : "inherit",
                }}
              >
                {route.badge ? (
                  <Badge badgeContent={route.badge} color="secondary">
                    <ListItemText primary={route.label} />
                  </Badge>
                ) : (
                  <ListItemText primary={route.label} />
                )}
              </ListItemButton>
            ))}
          </>
        )}
        {isAdminMode && isTenantAdmin && (
          <>
            <Typography
              variant="h6"
              sx={{
                paddingLeft: 2,
                paddingTop: 2,
                fontWeight: "bold",
                color: theme.palette.primary.main,
              }}
            >
              Tenant Admin
            </Typography>
            {tenantAdminRoutes.map((route, index) => (
              <ListItemButton
                key={index}
                component={Link}
                to={route.path}
                disabled={route.disabled || false}
                sx={{
                  backgroundColor:
                    location.pathname === route.path
                      ? theme.palette.primary.dark
                      : "inherit",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  color:
                    location.pathname === route.path
                      ? theme.palette.primary.contrastText
                      : "inherit",
                }}
              >
                {route.badge ? (
                  <Badge badgeContent={route.badge} color="secondary">
                    <ListItemText primary={route.label} />
                  </Badge>
                ) : (
                  <ListItemText primary={route.label} />
                )}
              </ListItemButton>
            ))}
          </>
        )}
        {isAdminMode && isPartnerAdmin && (
          <>
            <Typography
              variant="h6"
              sx={{
                paddingLeft: 2,
                paddingTop: 2,
                fontWeight: "bold",
                color: theme.palette.primary.main,
              }}
            >
              Partner Admin
            </Typography>
            {partnerAdminRoutes.map((route, index) => (
              <ListItemButton
                key={index}
                component={Link}
                to={route.path}
                disabled={route.disabled || false}
                sx={{
                  backgroundColor:
                    location.pathname === route.path
                      ? theme.palette.primary.dark
                      : "inherit",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  color:
                    location.pathname === route.path
                      ? theme.palette.primary.contrastText
                      : "inherit",
                }}
              >
                {route.badge ? (
                  <Badge badgeContent={route.badge} color="secondary">
                    <ListItemText primary={route.label} />
                  </Badge>
                ) : (
                  <ListItemText primary={route.label} />
                )}
              </ListItemButton>
            ))}
          </>
        )}
        {isAdminMode && isSuperAdmin && (
          <>
            <Typography
              variant="h6"
              sx={{
                paddingLeft: 2,
                paddingTop: 2,
                fontWeight: "bold",
                color: theme.palette.primary.main,
              }}
            >
              Super Admin
            </Typography>
            {superAdminRoutes.map((route, index) => (
              <ListItemButton
                key={index}
                component={Link}
                to={route.path}
                disabled={route.disabled || false}
                sx={{
                  backgroundColor:
                    location.pathname === route.path
                      ? theme.palette.primary.dark
                      : "inherit",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  color:
                    location.pathname === route.path
                      ? theme.palette.primary.contrastText
                      : "inherit",
                }}
              >
                {route.badge ? (
                  <Badge badgeContent={route.badge} color="secondary">
                    <ListItemText primary={route.label} />
                  </Badge>
                ) : (
                  <ListItemText primary={route.label} />
                )}
              </ListItemButton>
            ))}
          </>
        )}
      </List>
      <Box
        sx={{
          textAlign: "center",
          mb: 2,
          mx: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {currentUser &&
          (currentUser.role === TENANT_ADMIN_ROLE_NAME ||
            currentUser.partner_role === PARTNER_ADMIN_ROLE_NAME ||
            currentUser.super_role === SUPER_ADMIN_ROLE_NAME) && (
            <Button
              variant="outlined"
              onClick={toggleAdminMode}
              sx={{
                backgroundColor: theme.palette.background.default,
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              {isAdminMode ? "Switch to User View" : "Admin Dashboard"}
            </Button>
          )}
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.primary.dark
              : "default",
          "& .MuiToolbar-root": {
            padding: { xs: "0 24px", sm: "0 24px" }, // Reducing padding on smaller screens
          },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Link
            to="/"
            style={{
              display: "flex",
              alignItems: "center", // This ensures vertical centering
              color: "inherit",
              textDecoration: "none",
              flexGrow: 1,
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={logoIcon}
                alt="Company Icon"
                style={{
                  height: "40px",
                  marginRight: "10px",
                  display: "block", // Ensures image is treated as a block element
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  lineHeight: 1, // Tightens up the vertical spacing
                }}
              >
                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    display: { xs: "none", sm: "block" },
                    lineHeight: 1.2, // Adjust this value as needed
                  }}
                >
                  {currentUser.tenant_description}
                </Typography>
                {poweredBy && (
                  <Typography
                    variant="caption"
                    sx={{
                      opacity: 0.7,
                      display: { xs: "none", sm: "block" },
                      fontStyle: "italic",
                      lineHeight: 1.2, // Adjust this value as needed
                    }}
                  >
                    powered by L2C CRM
                  </Typography>
                )}
              </Box>
            </Box>
          </Link>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ justifyContent: "flex-end" }}
          >
            <Typography>
              {currentUser.firstName} {currentUser.lastName}
            </Typography>
            <Badge
              badgeContent={currentUser.tenant_name}
              color="secondary"
              sx={{
                "& .MuiBadge-badge": {
                  minWidth: "auto",
                  padding: "0 8px",
                  transform: "none",
                  position: "static",
                },
              }}
            />
            {hasDarkTheme && (
              <IconButton
                color="inherit"
                onClick={handleThemeToggle}
                sx={{ marginRight: 1 }}
              >
                {themeMode === "dark" ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>
            )}
            <IconButton color="inherit" onClick={handleLogout} sx={{ ml: 1 }}>
              <LogoutIcon />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 250,
              height: `calc(100vh - 64px)`,
              backgroundColor:
                theme.palette.mode === "light"
                  ? theme.palette.grey[200]
                  : "inherit",
              // color: theme.palette.getContrastText(theme.palette.grey[800]), // Ensures text is visible
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "box-border",
              width: 250,
              marginTop: `64px`,
              height: `calc(100vh - 64px)`,
              backgroundColor:
                theme.palette.mode === "light"
                  ? theme.palette.grey[200]
                  : "inherit",
              // color: theme.palette.getContrastText(theme.palette.grey[800]), // Ensures text is visible
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - 250px)` },
          ml: { sm: `250px` },
          mt: { xs: 7, sm: 8 },
          height: `calc(100vh - 64px)`, // Adjusted height to prevent overflow
          overflow: "auto", // Added overflow auto to main container
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
