import React, { useState, useEffect } from "react";
import { 
  Container, 
  Typography, 
  Button, 
  Snackbar, 
  Chip, 
  Tooltip, 
  Alert,
  Box
} from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import decode from "../../../general/util/jwtDecode";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";
import ShareIcon from '@mui/icons-material/Share';
import SyncIcon from '@mui/icons-material/Sync';
import InfoIcon from '@mui/icons-material/Info';

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const AdminGhlLocations = () => {
  const [locations, setLocations] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncingLocationId, setSyncingLocationId] = useState(null);
  const currentUser = decode();

  useEffect(() => {
    loadLocations();
  }, []);

  const loadLocations = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ghl-locations`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch locations");
      }
      const data = await response.json();
      // Combine first name and last name into account_owner
      const locationsWithOwner = data.map((location) => ({
        ...location,
        account_owner: `${location.first_name || ''} ${location.last_name || ''}`.trim(),
      }));
      setLocations(locationsWithOwner);
    } catch (error) {
      console.error("Failed to load locations:", error);
      setSnackbarMessage("Failed to load locations");
      setSnackbarSeverity("error");
    }
  };

  const handleSync = async (locationId) => {
    setIsSyncing(true);
    setSyncingLocationId(locationId);
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ghl-locations/${locationId}/syncCustomFields`,
        { method: "POST" }
      );
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to sync custom fields");
      }
      
      setSnackbarMessage("Custom fields synced successfully");
      setSnackbarSeverity("success");
    } catch (error) {
      console.error("Sync failed:", error);
      setSnackbarMessage(error.message || "Failed to sync custom fields");
      setSnackbarSeverity("error");
    } finally {
      setIsSyncing(false);
      setSyncingLocationId(null);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage("");
  };

  // Function to check if a location has sync permission
  const canSyncLocation = (location) => {
    if (!location.is_shared) return true;
    
    // Check permissions for shared locations
    return location.permissions && location.permissions.can_edit === true;
  };

  const columns = [
    { 
      field: "name", 
      headerName: "Account Name", 
      flex: 1.2,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {params.row.name}
          {params.row.is_shared && (
            <Tooltip title="Shared location">
              <ShareIcon 
                color="primary" 
                fontSize="small" 
                sx={{ ml: 1 }} 
              />
            </Tooltip>
          )}
        </Box>
      )
    },
    { field: "account_owner", headerName: "Account Owner", flex: 1 },
    { field: "email", headerName: "Email", flex: 1.2 },
    { field: "timezone", headerName: "Timezone", flex: 1 },
    {
      field: "source",
      headerName: "Source",
      flex: 0.8,
      renderCell: (params) => (
        params.row.is_shared ? (
          <Tooltip title={`Shared by tenant ID: ${params.row.shared_by_tenant_id}`}>
            <Chip 
              label="Shared" 
              color="primary" 
              size="small" 
              icon={<ShareIcon />} 
            />
          </Tooltip>
        ) : (
          <Chip label="Owned" size="small" />
        )
      )
    },
    {
      field: "sync",
      headerName: "Sync Custom Fields",
      flex: 1,
      renderCell: (params) => {
        const canSync = canSyncLocation(params.row);
        
        return (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            height: '100%'
          }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSync(params.row.id)}
              disabled={isSyncing || !canSync || syncingLocationId === params.row.id}
              startIcon={<SyncIcon />}
              size="small"
            >
              {syncingLocationId === params.row.id ? "Syncing..." : "Sync"}
            </Button>
            
            {params.row.is_shared && !canSync && (
              <Tooltip title="You don't have edit permission for this shared location">
                <InfoIcon color="warning" sx={{ ml: 1 }} />
              </Tooltip>
            )}
          </Box>
        );
      }
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <Container>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main", // Use the primary color from the theme
          marginBottom: 2, // Theme spacing unit
          textAlign: "left", // left align text
        }}
      >
        Go High Level - Agency Sub Accounts
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: 2, // Theme spacing unit
          textAlign: "left", // Center align text
        }}
      >
        Sub-accounts, formerly GHL Locations, are sub-accounts which have been
        synced from your agency account after adding the {currentUser.tenant_description}
        marketplace application. These accounts are used to link users and
        contact references within the {currentUser.tenant_description} application. These
        sub-accounts are edited in Go High Level and are automatically synced
        back to this screen on a schedule.
      </Typography>
      
      {locations.some(loc => loc.is_shared) && (
        <Alert severity="info" sx={{ mb: 2 }}>
          <Typography variant="body2">
            <strong>Shared locations:</strong> Some locations are shared with you by other tenants. 
            Your ability to sync custom fields for these locations depends on whether you have edit permission granted by the sharing tenant.
          </Typography>
        </Alert>
      )}
      
      <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
        <DataGridPro
          rows={locations}
          columns={columns}
          pageSize={10}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          headerFilters
          pageSizeOptions={[10, 25, 50]}
          pagination
          autoHeight
          slots={{
            toolbar: CustomToolbar,
            headerFilterMenu: null,
          }}
        />
      </div>
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminGhlLocations;
