import React, { useState, useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Switch,
  Typography,
  Chip,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";

const EditProductDialog = ({
  open,
  onClose,
  tags,
  mappingGroups,
  selectedGroup,
  allowsReplacement,
  isActive,
  onSave,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [currentTags, setCurrentTags] = useState(tags);
  const [selectedMappingGroup, setSelectedMappingGroup] = useState(selectedGroup);
  const [currentAllowsReplacement, setCurrentAllowsReplacement] = useState(allowsReplacement);
  const [currentIsActive, setCurrentIsActive] = useState(isActive);

  useEffect(() => {
    setCurrentTags(tags);
    setSelectedMappingGroup(selectedGroup);
    setCurrentAllowsReplacement(allowsReplacement);
    setCurrentIsActive(isActive);
  }, [tags, selectedGroup, allowsReplacement, isActive]);

  const handleAddTag = () => {
    if (inputValue && currentTags.length < 10) {
      setCurrentTags([...currentTags, inputValue]);
      setInputValue("");
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    setCurrentTags(currentTags.filter((tag) => tag !== tagToDelete));
  };

  const handleSave = () => {
    onSave(currentTags, selectedMappingGroup, currentAllowsReplacement, currentIsActive);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit Product Details</DialogTitle>
      <DialogContent>
        <Box pt={1}>
          <Typography variant="subtitle1" gutterBottom>
            CRM Tags
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
            <TextField
              label="Add Tag"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleAddTag();
                }
              }}
              helperText="Press Enter or click Add to create a tag"
              sx={{ flex: 1 }}
            />
            <Button
              onClick={handleAddTag}
              variant="outlined"
              disabled={!inputValue || currentTags.length >= 10}
              sx={{ height: 56 }}
            >
              Add
            </Button>
          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3 }}>
            {currentTags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleDeleteTag(tag)}
                color="primary"
                variant="outlined"
              />
            ))}
          </Box>

          <Typography variant="subtitle1" gutterBottom>
            Mapping Group
          </Typography>
          <Autocomplete
            options={mappingGroups}
            getOptionLabel={(option) => option.name}
            value={selectedMappingGroup}
            onChange={(event, newValue) => setSelectedMappingGroup(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Mapping Group"
                fullWidth
                sx={{ mb: 3 }}
              />
            )}
          />

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentAllowsReplacement}
                  onChange={(e) => setCurrentAllowsReplacement(e.target.checked)}
                  color="primary"
                />
              }
              label="Allow Replacements"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentIsActive}
                  onChange={(e) => setCurrentIsActive(e.target.checked)}
                  color="primary"
                />
              }
              label="Active"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TenantAdminProductCatalog = () => {
  const currentUser = decode();
  const [catalogItems, setCatalogItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemTags, setSelectedItemTags] = useState([]);
  const [mappingGroups, setMappingGroups] = useState([]);
  const [selectedMappingGroup, setSelectedMappingGroup] = useState(null);
  const [selectedAllowsReplacement, setSelectedAllowsReplacement] = useState(true);
  const [selectedIsActive, setSelectedIsActive] = useState(true);

  const fetchCatalogItems = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/product-catalog`
      );
      if (!response.ok) throw new Error("Failed to fetch catalog items");
      const data = await response.json();
      setCatalogItems(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchMappingGroups = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/mappingGroups`
      );
      if (!response.ok) throw new Error("Failed to fetch mapping groups");
      const data = await response.json();
      setMappingGroups(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchCatalogItems();
    fetchMappingGroups();
  }, []);

  const handleToggleActive = async (id, isActive) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/product-catalog/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ is_active: !isActive }),
        }
      );
      if (!response.ok) throw new Error("Failed to update catalog item");
      fetchCatalogItems();
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditProduct = (id, tags, mappingGroupId, allowsReplacement, isActive) => {
    const selectedGroup = mappingGroups.find(
      (group) => group.id === mappingGroupId
    );
    setSelectedItemId(id);
    setSelectedItemTags(tags);
    setSelectedMappingGroup(selectedGroup);
    setSelectedAllowsReplacement(allowsReplacement);
    setSelectedIsActive(isActive);
    setEditDialogOpen(true);
  };

  const handleSaveProductDetails = async (updatedTags, updatedMappingGroup, updatedAllowsReplacement, updatedIsActive) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/product-catalog/${selectedItemId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            crm_tags: updatedTags,
            mapping_group_id: updatedMappingGroup?.id,
            allows_replacement: updatedAllowsReplacement,
            is_active: updatedIsActive,
          }),
        }
      );
      if (!response.ok) throw new Error("Failed to update product details");
      setEditDialogOpen(false);
      fetchCatalogItems();
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    { 
      field: "name", 
      headerName: "Name", 
      flex: 1,
      minWidth: 200,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      minWidth: 120,
      renderCell: (params) => (
        <Chip 
          label={params.row.is_active ? "Active" : "Inactive"}
          color={params.row.is_active ? "success" : "default"}
          size="small"
        />
      ),
    },
    {
      field: "pricing",
      headerName: "Pricing",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const hasTiers = params.row.tiered_pricing && Object.keys(params.row.tiered_pricing).length > 0;
        
        return (
          <Tooltip title={hasTiers ? "Has tiered pricing" : params.row.price}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {params.row.price && <span>{params.row.price}</span>}
              {hasTiers && (
                <Chip 
                  label="Tiered" 
                  size="small" 
                  color="primary" 
                  variant="outlined"
                />
              )}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "allows_replacement",
      headerName: "Replacements",
      flex: 0.5,
      minWidth: 130,
      renderCell: (params) => (
        <Chip 
          label={params.row.allows_replacement ? "Allowed" : "Not Allowed"}
          color={params.row.allows_replacement ? "success" : "error"}
          size="small"
          variant="outlined"
        />
      ),
    },
    {
      field: "limits",
      headerName: "Limits",
      flex: 0.8,
      minWidth: 150,
      renderCell: (params) => {
        const limits = [];
        if (params.row.daily_cap) limits.push(`Cap: ${params.row.daily_cap}`);
        if (params.row.order_minimum) limits.push(`Min: ${params.row.order_minimum}`);
        
        return limits.length ? (
          <Tooltip title={limits.join(', ')}>
            <Chip label={limits.length === 2 ? "Cap & Min" : limits[0]} size="small" />
          </Tooltip>
        ) : "—";
      },
    },
    {
      field: "tenant_groups",
      headerName: "Groups",
      flex: 0.6,
      minWidth: 100,
      renderCell: (params) => (
        <Tooltip title={params.value.map((group) => group.name).join(", ")}>
          <Chip 
            label={`${params.value.length} group${params.value.length === 1 ? '' : 's'}`}
            size="small"
            variant="outlined"
          />
        </Tooltip>
      ),
    },
    {
      field: "crm_tags",
      headerName: "Tags",
      flex: 0.6,
      minWidth: 100,
      renderCell: (params) => (
        params.value?.length > 0 ? (
          <Tooltip title={params.value.join(", ")}>
            <Chip 
              label={`${params.value.length} tag${params.value.length === 1 ? '' : 's'}`}
              size="small"
              variant="outlined"
            />
          </Tooltip>
        ) : "—"
      ),
    },
    {
      field: "mapping_group_name",
      headerName: "Mapping",
      flex: 0.8,
      minWidth: 130,
      renderCell: (params) => (
        <Tooltip title={params.value || "No Mapping Group"}>
          <Chip
            label={params.value || "None"}
            size="small"
            variant="outlined"
          />
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,
      getActions: (params) => [
        <IconButton
          onClick={() =>
            handleEditProduct(
              params.id,
              params.row.crm_tags,
              params.row.mapping_group_id,
              params.row.allows_replacement,
              params.row.is_active
            )
          }
        >
          <EditIcon />
        </IconButton>
      ],
    },
  ];

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading catalog items: {error}</p>;

  return (
    <div style={{ height: 600, width: "100%" }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Product Catalog
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Manage your product catalog settings, including CRM tags, mapping groups, and replacement options.
          Products and pricing are configured by your partner administrator.
        </Typography>
      </Box>

      <DataGridPro
        rows={catalogItems}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
        disableSelectionOnClick
        autoHeight
        density="comfortable"
        initialState={{
          columns: {
            columnVisibilityModel: {
              product_type_name: false,
            },
          },
        }}
      />

      <EditProductDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        tags={selectedItemTags}
        mappingGroups={mappingGroups}
        selectedGroup={selectedMappingGroup}
        allowsReplacement={selectedAllowsReplacement}
        isActive={selectedIsActive}
        onSave={handleSaveProductDetails}
      />
    </div>
  );
};

export default TenantAdminProductCatalog;
