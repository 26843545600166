// authBrandingUtils.js
import { staticBranding } from "../constants/staticBranding";
import { updateFavicon } from "../layout/faviconUtils";

/**
 * Updates the document title and favicon based on the current domain's branding
 * but respects server-side branding if present
 */
export const updateBranding = () => {
  // Check if server-side branding exists
  if (window.__SERVER_BRANDING__) {
    console.log(`[CLIENT-DEBUG] Server-side branding found, using that instead of client-side branding`);
    return;
  }
  
  const currentDomain = window.location.hostname;
  console.log(`[CLIENT-DEBUG] No server-side branding found. Updating branding for domain: ${currentDomain}`);
  
  const brandingData = staticBranding[currentDomain];
  console.log(`[CLIENT-DEBUG] Found branding data:`, brandingData);
  
  // Set document title
  if (brandingData?.description) {
    console.log(`[CLIENT-DEBUG] Setting document title to: ${brandingData.description}`);
    document.title = brandingData.description;
  } else {
    console.log(`[CLIENT-DEBUG] Using default document title: "Lead Generation Software"`);
    document.title = "Lead Generation Software";
  }
  
  // Update favicon
  if (brandingData?.light?.logoIcon) {
    console.log(`[CLIENT-DEBUG] Updating favicon to: ${brandingData.light.logoIcon}`);
    updateFavicon(brandingData.light.logoIcon).catch((error) => {
      console.error("[CLIENT-DEBUG] Failed to update favicon:", error);
    });
  } else {
    console.log(`[CLIENT-DEBUG] Using default favicon`);
  }
};
