import React, { useState } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PartnerAdminButtons() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleAction = async (url, actionName) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Request failed");
      setSnackbar({
        open: true,
        message: `${actionName} was successful!`,
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `${actionName} failed!`,
        severity: "error",
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
        marginTop: "40px",
        marginBottom: "40px",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        variant="contained"
        onClick={() => handleAction("/v1/cron/clientads", "Process Maxxed")}
      >
        Process Maxxed
      </Button>
      <Button
        variant="contained"
        onClick={() => handleAction("/v1/cron/synccampaigns", "Sync FB Campaigns/Ads")}
      >
        Sync FB Campaigns/Ads
      </Button>
      <Button
        variant="contained"
        onClick={() => handleAction("/v1/cron/syncstripe", "Sync Stripe")}
      >
        Sync Stripe
      </Button>
      <Button
        variant="contained"
        onClick={() =>
          handleAction("/v1/cron/processadsets", "Ad Sets On/Off")
        }
      >
        Ad Sets On/Off
      </Button>
      <Button
        variant="contained"
        onClick={() =>
          handleAction("/v1/cron/processcampaigns", "Campaigns On/Off")
        }
      >
        Campaigns On/Off
      </Button>
      <Button
        variant="contained"
        onClick={() =>
          handleAction("/v1/cron/syncghlusers", "Sync GHL Users")
        }
      >
        Sync GHL Users
      </Button>
      <Button
        variant="contained"
        onClick={() =>
          handleAction("/v1/cron/syncghllocations", "Sync Sub-Accounts")
        }
      >
        Sync Sub-Accounts
      </Button>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
