import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Paper,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Chip,
  Tooltip,
  Alert,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Divider,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import decode from "../../../general/util/jwtDecode";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const SERVER_BASE_URL = process.env.SERVER_BASE_URL || "https://localhost:3000";

const SharedGhlLocationsPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [allLocations, setAllLocations] = useState([]);
  const [sharedLocations, setSharedLocations] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [potentialTenants, setPotentialTenants] = useState([]);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [shareToDelete, setShareToDelete] = useState(null);
  const [loading, setLoading] = useState({
    locations: false,
    shares: false,
    tenants: false,
    potentialTenants: false,
  });
  const [permissions, setPermissions] = useState({
    can_view: true,
    can_edit: false,
    can_delete: false,
  });
  const currentUser = decode();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadAllLocations();
    loadSharedLocations();
    loadTenants();
  };

  const loadAllLocations = async () => {
    setLoading((prev) => ({ ...prev, locations: true }));
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/ghl-locations`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch locations");
      }
      const data = await response.json();
      setAllLocations(data);
    } catch (error) {
      console.error("Failed to load locations:", error);
    } finally {
      setLoading((prev) => ({ ...prev, locations: false }));
    }
  };

  const loadSharedLocations = async () => {
    setLoading((prev) => ({ ...prev, shares: true }));
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/shared-ghl-locations`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch shared locations");
      }
      const data = await response.json();
      setSharedLocations(data);
    } catch (error) {
      console.error("Failed to load shared locations:", error);
    } finally {
      setLoading((prev) => ({ ...prev, shares: false }));
    }
  };

  const loadTenants = async () => {
    setLoading((prev) => ({ ...prev, tenants: true }));
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/tenants`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch tenants");
      }
      const data = await response.json();
      setTenants(data);
    } catch (error) {
      console.error("Failed to load tenants:", error);
    } finally {
      setLoading((prev) => ({ ...prev, tenants: false }));
    }
  };

  const loadPotentialTenants = async (locationId) => {
    setLoading((prev) => ({ ...prev, potentialTenants: true }));
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/ghl-locations/${locationId}/potential-tenants`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch potential tenants");
      }
      const data = await response.json();
      setPotentialTenants(data);
    } catch (error) {
      console.error("Failed to load potential tenants:", error);
    } finally {
      setLoading((prev) => ({ ...prev, potentialTenants: false }));
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenShareDialog = (location) => {
    setSelectedLocation(location);
    loadPotentialTenants(location.id);
    setOpenShareDialog(true);
  };

  const handleCloseShareDialog = () => {
    setOpenShareDialog(false);
    setSelectedLocation(null);
    setSelectedTenant(null);
    setPotentialTenants([]);
    setPermissions({
      can_view: true,
      can_edit: false,
      can_delete: false,
    });
  };

  const handleOpenConfirmDeleteDialog = (share) => {
    setShareToDelete(share);
    setOpenConfirmDeleteDialog(true);
  };

  const handleCloseConfirmDeleteDialog = () => {
    setOpenConfirmDeleteDialog(false);
    setShareToDelete(null);
  };

  const handleTenantChange = (event, value) => {
    setSelectedTenant(value);
  };

  const handlePermissionChange = (permission) => (event) => {
    setPermissions({
      ...permissions,
      [permission]: event.target.checked,
    });
  };

  const handleShareLocation = async () => {
    if (!selectedLocation || !selectedTenant) return;

    try {
      const payload = {
        tenant_id: selectedTenant.id,
        ghl_location_id: selectedLocation.id,
        shared_by_tenant_id: selectedLocation.tenant_id,
        permissions: permissions,
      };

      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/shared-ghl-locations`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to share location");
      }

      loadSharedLocations();
      handleCloseShareDialog();
    } catch (error) {
      console.error("Failed to share location:", error);
    }
  };

  const handleDeleteShare = async () => {
    if (!shareToDelete) return;

    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/shared-ghl-locations/${shareToDelete.id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete shared location");
      }

      loadSharedLocations();
      handleCloseConfirmDeleteDialog();
    } catch (error) {
      console.error("Failed to delete shared location:", error);
    }
  };

  const locationColumns = [
    {
      field: "id",
      headerName: "ID",
      align: "left",
      headerAlign: "left",
      type: "number",
      width: 70,
    },
    { field: "tenant_name", headerName: "Tenant", flex: 1 },
    { field: "company_name", headerName: "Company", flex: 1 },
    { field: "name", headerName: "Location Name", flex: 1.5 },
    { field: "city", headerName: "City", width: 120 },
    { field: "state", headerName: "State", width: 100 },
    {
      field: "share_count",
      headerName: "Shared With",
      sortable: false,
      filterable: false,
      width: 120,
      renderCell: (params) => (
        <Chip
          label={params.value || 0}
          color={params.value > 0 ? "primary" : "default"}
          variant={params.value > 0 ? "default" : "outlined"}
          size="small"
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Share Location">
            <IconButton onClick={() => handleOpenShareDialog(params.row)}>
              <ShareIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const sharedLocationsColumns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "location_name", headerName: "Location Name", flex: 1.5 },
    { field: "shared_by_tenant_name", headerName: "Shared By", flex: 1 },
    { field: "tenant_name", headerName: "Shared With", flex: 1 },
    {
      field: "permissions",
      headerName: "Permissions",
      flex: 1,
      renderCell: (params) => {
        const permissions = params.value || {};
        return (
          <Box>
            {permissions.can_view && (
              <Chip
                label="View"
                color="primary"
                size="small"
                sx={{ mr: 0.5 }}
              />
            )}
            {permissions.can_edit && (
              <Chip
                label="Edit"
                color="secondary"
                size="small"
                sx={{ mr: 0.5 }}
              />
            )}
            {permissions.can_delete && (
              <Chip label="Delete" color="error" size="small" />
            )}
          </Box>
        );
      },
    },
    {
      field: "created_time",
      headerName: "Shared On",
      width: 180,
      valueFormatter: (value, row) => {
        return new Date(row.updated_time).toLocaleString();
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Remove Sharing">
            <IconButton
              onClick={() => handleOpenConfirmDeleteDialog(params.row)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Shared GHL Locations
      </Typography>
      <Typography variant="body1" gutterBottom>
        Manage Go High Level location sharing between tenants. This allows
        tenants to access GHL locations from other tenants.
      </Typography>

      <Paper sx={{ mb: 3 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label="All Locations"
            icon={<LocationOnIcon />}
            iconPosition="start"
          />
          <Tab
            label="Shared Locations"
            icon={<ShareIcon />}
            iconPosition="start"
          />
        </Tabs>
      </Paper>

      {tabValue === 0 && (
        <Box sx={{ height: 600, width: "100%" }}>
          {loading.locations ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGridPro
              rows={allLocations}
              columns={locationColumns}
              pageSize={10}
              headerFilters
              pagination
              rowsPerPageOptions={[10, 25, 50]}
              disableSelectionOnClick
              loading={loading.locations}
            />
          )}
        </Box>
      )}

      {tabValue === 1 && (
        <Box sx={{ height: 600, width: "100%" }}>
          {loading.shares ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGridPro
              rows={sharedLocations}
              columns={sharedLocationsColumns}
              pageSize={10}
              headerFilters
              pagination
              rowsPerPageOptions={[10, 25, 50]}
              disableSelectionOnClick
              loading={loading.shares}
            />
          )}
        </Box>
      )}

      {/* Share Location Dialog */}
      <Dialog
        open={openShareDialog}
        onClose={handleCloseShareDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Share GHL Location</DialogTitle>
        <DialogContent>
          {selectedLocation && (
            <>
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Location Details:
                </Typography>
                <Typography variant="body1">
                  <strong>Name:</strong> {selectedLocation.name}
                </Typography>
                <Typography variant="body1">
                  <strong>Tenant:</strong> {selectedLocation.tenant_name}
                </Typography>
                <Typography variant="body1">
                  <strong>Company:</strong> {selectedLocation.company_name}
                </Typography>
                <Typography variant="body1">
                  <strong>Address:</strong>{" "}
                  {`${selectedLocation.address || ""}, ${
                    selectedLocation.city || ""
                  }, ${selectedLocation.state || ""} ${
                    selectedLocation.postal_code || ""
                  }`}
                </Typography>
              </Box>

              <Divider sx={{ my: 2 }} />

              <Typography variant="subtitle1" gutterBottom>
                Share with Tenant:
              </Typography>

              {loading.potentialTenants ? (
                <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : potentialTenants.length === 0 ? (
                <Alert severity="info" sx={{ my: 2 }}>
                  No tenants available for sharing. This location may already be
                  shared with all tenants.
                </Alert>
              ) : (
                <>
                  <Autocomplete
                    id="tenant-select"
                    options={potentialTenants}
                    getOptionLabel={(option) => option.name}
                    value={selectedTenant}
                    onChange={handleTenantChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Tenant"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />
                    )}
                  />

                  <Box sx={{ mt: 3 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Permissions:
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permissions.can_view}
                          onChange={handlePermissionChange("can_view")}
                          disabled={true} // Always required
                        />
                      }
                      label="Can View"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permissions.can_edit}
                          onChange={handlePermissionChange("can_edit")}
                        />
                      }
                      label="Can Edit"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permissions.can_delete}
                          onChange={handlePermissionChange("can_delete")}
                        />
                      }
                      label="Can Delete"
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShareDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleShareLocation}
            color="primary"
            disabled={!selectedTenant || loading.potentialTenants}
          >
            Share Location
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Dialog */}
      <Dialog
        open={openConfirmDeleteDialog}
        onClose={handleCloseConfirmDeleteDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirm Remove Sharing</DialogTitle>
        <DialogContent>
          {shareToDelete && (
            <Typography variant="body1">
              Are you sure you want to remove sharing of location{" "}
              <strong>{shareToDelete.location_name}</strong> from tenant{" "}
              <strong>{shareToDelete.tenant_name}</strong>?
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteShare} color="error">
            Remove Sharing
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SharedGhlLocationsPage;
