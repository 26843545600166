/**
 * Calculates the price for an order based on tiered pricing rules
 * @param {Object} product - The product details including tiered pricing configuration
 * @param {number} quantity - The order quantity
 * @param {Array<string>} states - Array of state codes
 * @returns {Object} Price information including per-lead price, total price, and applied tier
 */
export const calculatePrice = (product, quantity, states) => {
  if (!product) return null;

  // If no tiered pricing or empty tiers, use base price
  if (!product.tiered_pricing || !product.tiered_pricing.tiers || product.tiered_pricing.tiers.length === 0) {
    return {
      pricePerLead: product.price,
      totalPrice: product.price * quantity,
      tierType: null,
      appliedTier: null
    };
  }

  const { tier_type, tiers } = product.tiered_pricing;
  let pricePerLead = product.price; // Default to base price
  let appliedTier = null;

  switch (tier_type) {
    case 'state_count':
      if (states && states.length > 0) {
        const stateCount = states.length;
        const matchingTier = tiers
          .filter(tier => stateCount >= tier.min_states)
          .sort((a, b) => b.min_states - a.min_states)[0];
        
        if (matchingTier) {
          pricePerLead = matchingTier.price_per_lead;
          appliedTier = matchingTier;
        }
      }
      break;

    case 'state_group':
      if (states && states.length > 0) {
        let maxPrice = product.price;
        let maxPriceTier = null;
        for (const state of states) {
          for (const tier of tiers) {
            if (tier.states.includes(state) || tier.states.includes('*')) {
              if (tier.price_per_lead > maxPrice) {
                maxPrice = tier.price_per_lead;
                maxPriceTier = tier;
              }
            }
          }
        }
        pricePerLead = maxPrice;
        appliedTier = maxPriceTier;
      }
      break;

    case 'order_quantity':
      const matchingTier = tiers
        .filter(tier => quantity >= tier.min_quantity)
        .sort((a, b) => b.min_quantity - a.min_quantity)[0];
      
      if (matchingTier) {
        pricePerLead = matchingTier.price_per_lead;
        appliedTier = matchingTier;
      }
      break;
  }

  return {
    pricePerLead,
    totalPrice: pricePerLead * quantity,
    tierType: tier_type,
    appliedTier
  };
};