import React, { useState, useEffect } from "react";
import { 
  Typography, 
  Box, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Badge,
  Stack
} from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import customFetch from "../../../general/auth/customFetch";
import EditCampaignDialog from "./components/EditCampaignDialog";
import ScheduleDialog from "./components/ScheduleDialog";
import ConfirmDialog from "./components/ConfirmDialog";
import decode from "../../../general/util/jwtDecode";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function AdminCampaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const [tenants, setTenants] = useState({});
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const currentUser = decode();

  // Function to fetch campaigns data from the API
  const fetchCampaigns = () => {
    customFetch(`/v1/partners/${currentUser.partner_id}/campaigns`)
      .then((response) => response.json())
      .then((data) => {
        // Organize campaigns by tenant and integration
        const tenantMap = {};
        
        data.forEach(campaign => {
          const tenantId = campaign.tenant_id;
          const integrationId = campaign.integration_id;
          
          if (!tenantMap[tenantId]) {
            tenantMap[tenantId] = {
              id: tenantId,
              name: campaign.tenant_name,
              integrations: {}
            };
          }
          
          if (!tenantMap[tenantId].integrations[integrationId]) {
            tenantMap[tenantId].integrations[integrationId] = {
              id: integrationId,
              name: campaign.integration_name,
              campaigns: []
            };
          }
          
          tenantMap[tenantId].integrations[integrationId].campaigns.push(campaign);
        });
        
        setCampaigns(data);
        setTenants(tenantMap);
      })
      .catch((error) => console.error("Error fetching campaigns:", error));
  };

  useEffect(() => {
    // Fetch campaigns on component mount
    fetchCampaigns();
  }, []);

  const handleEdit = (id, updatedCampaign) => {
    // Update the campaign in the state
    setCampaigns((prevCampaigns) =>
      prevCampaigns.map((campaign) =>
        campaign.id === id ? { ...campaign, ...updatedCampaign } : campaign
      )
    );

    // Prepare the data to send to the server
    const campaignData = {
      custom_name: updatedCampaign.custom_name,
      description: updatedCampaign.description,
      industry: updatedCampaign.industry,
      niche: updatedCampaign.niche,
      isactive: updatedCampaign.isactive,
      is_automated: updatedCampaign.is_automated
    };

    // Send the update to the server
    customFetch(`/v1/partners/${currentUser.partner_id}/campaigns/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(campaignData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(() => {
        // Refresh campaigns data to ensure we have the latest state
        fetchCampaigns();
      })
      .catch((error) => console.error("Error updating campaign:", error));
  };
  
  const handleEditClick = (campaign) => {
    setSelectedCampaign(campaign);
    setOpenEdit(true);
  };

  const handleScheduleClick = (campaign) => {
    setSelectedCampaign(campaign);
    setOpenSchedule(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseSchedule = () => {
    setOpenSchedule(false);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleSaveSchedule = (newSchedule) => {
    // After saving the schedule, refresh all campaign data to ensure consistency
    fetchCampaigns();
    setOpenSchedule(false);
  };

  const handleDeleteSchedule = () => {
    setOpenConfirm(true);
  };

  const confirmDeleteSchedule = () => {
    customFetch(`/v1/partners/${currentUser.partner_id}/campaigns/${selectedCampaign.id}/schedules/${selectedCampaign.schedule.id}`, {
      method: 'DELETE',
    })
      .then(() => {
        // After deleting the schedule, refresh all campaign data to ensure consistency
        fetchCampaigns();
        setOpenConfirm(false);
        setOpenSchedule(false);
      })
      .catch((error) => console.error('Error deleting schedule:', error));
  };

  const getStatusColor = (status) => {
    const statusColors = {
      ACTIVE: "success",
      PAUSED: "warning",
      DELETED: "error",
      PENDING: "info",
      IN_REVIEW: "secondary",
    };
    return statusColors[status] || "default";
  };

  return (
    <div className="content">
      <div style={{ width: "100%" }}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main",
            marginBottom: 2,
            textAlign: "center",
          }}
        >
          Campaigns
        </Typography>
        
        {Object.values(tenants).map((tenant) => (
          <Accordion key={tenant.id} sx={{ mb: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="h5" fontWeight="bold">
                {tenant.name}
              </Typography>
              <Badge 
                badgeContent={Object.values(tenant.integrations).reduce(
                  (count, integration) => count + integration.campaigns.length, 0
                )} 
                color="primary"
                sx={{ ml: 2 }}
              >
                <Typography variant="body1" sx={{ ml: 2 }}>
                  Campaigns
                </Typography>
              </Badge>
            </AccordionSummary>
            <AccordionDetails>
              {Object.values(tenant.integrations).map((integration) => (
                <Box key={integration.id} sx={{ mb: 3 }}>
                  <Paper 
                    elevation={3} 
                    sx={{ 
                      p: 2, 
                      mb: 2, 
                      backgroundColor: "background.paper" 
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Integration: {integration.name} (ID: {integration.id})
                    </Typography>
                    
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                      <Table size="small" sx={{ tableLayout: 'fixed' }}>
                        <TableHead>
                          <TableRow>
                            <TableCell width="20%">Campaign Name</TableCell>
                            <TableCell width="10%">Status</TableCell>
                            <TableCell width="15%">Account ID</TableCell>
                            <TableCell width="8%">Results</TableCell>
                            <TableCell width="8%">Spent</TableCell>
                            <TableCell width="12%">Schedule</TableCell>
                            <TableCell width="12%">Automation</TableCell>
                            <TableCell width="15%">Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {integration.campaigns.map((campaign) => (
                            <TableRow key={campaign.id} hover>
                              <TableCell>
                                <Tooltip title={campaign.description || "No description"}>
                                  <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Typography noWrap={false} sx={{ wordBreak: 'break-word' }}>
                                      {campaign.custom_name || campaign.campaign_name}
                                    </Typography>
                                    <Chip 
                                      label={`ID: ${campaign.id}`}
                                      size="small"
                                      variant="outlined"
                                      sx={{ 
                                        ml: 1, 
                                        height: '20px', 
                                        fontSize: '0.7rem',
                                        '& .MuiChip-label': { 
                                          px: 0.75 
                                        }
                                      }}
                                    />
                                  </Box>
                                </Tooltip>
                              </TableCell>
                              <TableCell>
                                <Chip 
                                  label={campaign.status} 
                                  color={getStatusColor(campaign.status)}
                                  size="small"
                                />
                              </TableCell>
                              <TableCell sx={{ wordBreak: 'break-all' }}>{campaign.account_id}</TableCell>
                              <TableCell>{campaign.results}</TableCell>
                              <TableCell>
                                ${parseFloat(campaign.amount_spent).toFixed(2)}
                              </TableCell>
                              <TableCell>
                                {campaign.schedule ? (
                                  <Tooltip 
                                    title={`${campaign.schedule.start_time} - ${campaign.schedule.end_time} (${campaign.schedule.active_days.join(", ")})`}
                                  >
                                    <Chip 
                                      icon={<ScheduleIcon />} 
                                      label="Active" 
                                      color="success" 
                                      size="small"
                                    />
                                  </Tooltip>
                                ) : (
                                  <Chip label="No Schedule" size="small" />
                                )}
                              </TableCell>
                              <TableCell>
                                {campaign.is_automated ? (
                                  <Tooltip title="This campaign is managed by automation">
                                    <Chip 
                                      icon={<AutoFixHighIcon />} 
                                      label="Enabled" 
                                      color="primary" 
                                      size="small"
                                    />
                                  </Tooltip>
                                ) : (
                                  <Chip 
                                    label="Disabled" 
                                    variant="outlined"
                                    size="small"
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                <Stack direction="row" spacing={1}>
                                  <Tooltip title="Edit Campaign">
                                    <IconButton 
                                      size="small" 
                                      color="primary"
                                      onClick={() => handleEditClick(campaign)}
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={campaign.schedule ? "Edit Schedule" : "Create Schedule"}>
                                    <IconButton 
                                      size="small" 
                                      color="secondary"
                                      onClick={() => handleScheduleClick(campaign)}
                                    >
                                      <ScheduleIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      
      {selectedCampaign && (
        <>
          <EditCampaignDialog 
            open={openEdit} 
            onClose={handleCloseEdit} 
            campaign={selectedCampaign} 
            onEdit={handleEdit} 
          />
          <ScheduleDialog
            open={openSchedule}
            onClose={handleCloseSchedule}
            campaignId={selectedCampaign.id}
            schedule={selectedCampaign.schedule}
            onSave={handleSaveSchedule}
            onDelete={handleDeleteSchedule}
          />
          <ConfirmDialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            onConfirm={confirmDeleteSchedule}
            title="Confirm Delete"
            content="Are you sure you want to delete this schedule?"
          />
        </>
      )}
    </div>
  );
}
