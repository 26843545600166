import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  Chip,
  Stack,
  Divider,
} from "@mui/material";
import decode from "../../../../general/util/jwtDecode";
import customFetch from "../../../../general/auth/customFetch";

export default function EditStripeSubscriptionDialog({
  open,
  onClose,
  group,
  onEditStripeSubscription,
}) {
  const [stripeData, setStripeData] = useState({
    stripe_product_ids: [],
    stripe_price_ids: [],
    stripe_subscription_ids: [],
  });
  const [newStripeId, setNewStripeId] = useState({
    product: "",
    price: "",
    subscription: "",
  });
  const [loading, setLoading] = useState(false);

  const currentUser = decode();

  useEffect(() => {
    if (group && open) {
      fetchStripeSubscriptionData();
    }
  }, [group, open]);

  const fetchStripeSubscriptionData = async () => {
    if (!group) return;
    
    setLoading(true);
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${group.id}/stripe-subscription-data`,
        { method: "GET" }
      );
      
      if (response.ok) {
        const data = await response.json();
        setStripeData({
          stripe_product_ids: data?.stripe_product_ids || [],
          stripe_price_ids: data?.stripe_price_ids || [],
          stripe_subscription_ids: data?.stripe_subscription_ids || [],
        });
      } else {
        // If no data exists yet, initialize with empty arrays
        setStripeData({
          stripe_product_ids: [],
          stripe_price_ids: [],
          stripe_subscription_ids: [],
        });
      }
    } catch (error) {
      console.error("Error fetching stripe subscription data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleStripeIdChange = (e, type) => {
    setNewStripeId({ ...newStripeId, [type]: e.target.value });
  };

  const handleAddStripeId = (type) => {
    if (!newStripeId[type]) return;

    const updatedData = { ...stripeData };
    const fieldName = `stripe_${type}_ids`;

    if (!updatedData[fieldName].includes(newStripeId[type])) {
      updatedData[fieldName] = [...updatedData[fieldName], newStripeId[type]];
      setStripeData(updatedData);
    }

    setNewStripeId({ ...newStripeId, [type]: "" });
  };

  const handleRemoveStripeId = (type, id) => {
    const updatedData = { ...stripeData };
    const fieldName = `stripe_${type}_ids`;

    updatedData[fieldName] = updatedData[fieldName].filter(
      (item) => item !== id
    );
    setStripeData(updatedData);
  };

  const handleSave = () => {
    onEditStripeSubscription(stripeData);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Edit Auto Share GHL Settings</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          These are the product, price, and/or subscription IDs which each GHL subaccount 
          is signed up on, allowing the lookup and auto sharing based on this group.
        </Typography>
        <Divider sx={{ mb: 3 }} />

        {/* Stripe Product IDs */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2">Stripe Product IDs</Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}
          >
            <TextField
              placeholder="Enter Stripe Product ID"
              value={newStripeId.product}
              onChange={(e) => handleStripeIdChange(e, "product")}
              size="small"
              fullWidth
            />
            <Button
              variant="contained"
              size="small"
              onClick={() => handleAddStripeId("product")}
              disabled={!newStripeId.product}
            >
              Add
            </Button>
          </Box>
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {stripeData.stripe_product_ids.map((id, index) => (
              <Chip
                key={index}
                label={id}
                onDelete={() => handleRemoveStripeId("product", id)}
                sx={{ mb: 1 }}
              />
            ))}
          </Stack>
        </Box>

        {/* Stripe Price IDs */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2">Stripe Price IDs</Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}
          >
            <TextField
              placeholder="Enter Stripe Price ID"
              value={newStripeId.price}
              onChange={(e) => handleStripeIdChange(e, "price")}
              size="small"
              fullWidth
            />
            <Button
              variant="contained"
              size="small"
              onClick={() => handleAddStripeId("price")}
              disabled={!newStripeId.price}
            >
              Add
            </Button>
          </Box>
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {stripeData.stripe_price_ids.map((id, index) => (
              <Chip
                key={index}
                label={id}
                onDelete={() => handleRemoveStripeId("price", id)}
                sx={{ mb: 1 }}
              />
            ))}
          </Stack>
        </Box>

        {/* Stripe Subscription IDs */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2">Stripe Subscription IDs</Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}
          >
            <TextField
              placeholder="Enter Stripe Subscription ID"
              value={newStripeId.subscription}
              onChange={(e) => handleStripeIdChange(e, "subscription")}
              size="small"
              fullWidth
            />
            <Button
              variant="contained"
              size="small"
              onClick={() => handleAddStripeId("subscription")}
              disabled={!newStripeId.subscription}
            >
              Add
            </Button>
          </Box>
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {stripeData.stripe_subscription_ids.map((id, index) => (
              <Chip
                key={index}
                label={id}
                onDelete={() => handleRemoveStripeId("subscription", id)}
                sx={{ mb: 1 }}
              />
            ))}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
