import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import PartnerAdminButtons from "./components/PartnerAdminButtons.js";
import decode from "../../../general/util/jwtDecode.js";
import { LicenseInfo } from "@mui/x-license";
import { PARTNER_ADMIN_ROLE_NAME } from "../../../general/constants/constants";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function PartnerAdminDashboard() {
  const currentUser = decode();

  useEffect(() => {
    if (currentUser && !currentUser.id) {
      window.location = "/login";
    } else if (currentUser && currentUser.partner_role !== PARTNER_ADMIN_ROLE_NAME) {
      window.location = "/";
    }
  }, []);

  return (
    <div className="content">
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main", // Use the primary color from the theme
          marginBottom: 2, // Theme spacing unit
          textAlign: "center", // Center align text
        }}
      >
        Partner Dashboard
      </Typography>
      <PartnerAdminButtons />

    </div>
  );
}