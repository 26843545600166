import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CreateProductLead from "./components/CreateProductLead";
import EditLead from "./components/EditLead";
import DeleteLead from "./components/DeleteLead";
import decode from "../../../general/util/jwtDecode";
import {
  Typography,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Container,
  Paper,
} from "@mui/material";
import {
  DataGridPro,
  getGridDateOperators,
  getGridNumericOperators,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import RepushLead from "./components/RepushLead";
import { LicenseInfo } from "@mui/x-license";
import CustomNoRowsOverlay from "../../../general/layout/CustomNoRowsOverlay";
import customFetch from "../../../general/auth/customFetch";
import debounce from "lodash.debounce";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function AdminLeads() {
  const currentUser = decode();
  const tenantId = currentUser?.tenant_id; // Extract tenant_id once
  const navigate = useNavigate();
  const location = useLocation();

  // Local states for the data grid and product/states data
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });

  const [productsList, setProductsList] = useState([]);
  const [states, setStates] = useState([]);

  // We will parse URL parameters once on mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // Parse pagination
    const page = parseInt(searchParams.get("page"), 10) || 0;
    const pageSize = parseInt(searchParams.get("pageSize"), 10) || 10;
    setPaginationModel({ page, pageSize });

    // Parse filters
    const filtersString = searchParams.get("filters");
    if (filtersString) {
      try {
        const filters = JSON.parse(filtersString);
        setFilterModel(filters);
      } catch (error) {
        console.error("Error parsing filters from URL:", error);
      }
    }

    // Parse sorting
    const sortString = searchParams.get("sort");
    if (sortString) {
      try {
        const sort = JSON.parse(sortString);
        setSortModel(sort);
      } catch (error) {
        console.error("Error parsing sort from URL:", error);
      }
    }
    // Run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const dateOperators = getGridDateOperators().filter((operator) =>
    ["is", "after", "onOrAfter", "before", "onOrBefore"].includes(
      operator.value
    )
  );

  const parseISODateString = (isoString) => {
    const date = new Date(isoString);
    return isNaN(date.getTime()) ? null : date;
  };

  const parseISODateStringNoTimezone = (isoString) => {
    if (!isoString) return null;
    const localISOString = isoString.replace("Z", "");
    const date = new Date(localISOString);
    return isNaN(date.getTime()) ? null : date;
  };

  const getProductName = useCallback(
    (productId, lead) => {
      if (productId) {
        const product = productsList.find((p) => p.id === productId);
        return product ? product.name : "";
      } else if (lead?.campaign_name || lead?.ad_set_name) {
        const parts = [];
        if (lead.campaign_name) parts.push(`Campaign: ${lead.campaign_name}`);
        if (lead.ad_set_name) parts.push(`Ad Set: ${lead.ad_set_name}`);
        return parts.join(" | ");
      }
      return "";
    },
    [productsList]
  );

  const fetchProductsAsync = useCallback(async () => {
    if (!tenantId) return; // Early return if no tenantId
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${tenantId}/product-catalog`,
        { method: "GET" }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setProductsList(data);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  }, [tenantId]); // Only depend on tenantId

  const fetchStatesAsync = useCallback(async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/orders/running-states`,
        { method: "GET" }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setStates(data);
    } catch (error) {
      console.error("Failed to fetch states:", error);
    }
  }, [currentUser.tenant_id]);

  useEffect(() => {
    fetchStatesAsync();
  }, [fetchStatesAsync]);

  useEffect(() => {
    fetchProductsAsync();
  }, [fetchProductsAsync]); // Now safe to include fetchProductsAsync

  // Debounced fetch leads
  const debouncedFetchLeads = useCallback(
    debounce(async () => {
      setLoading(true);

      const params = new URLSearchParams({
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
      });

      if (sortModel[0]?.field && sortModel[0]?.sort) {
        params.append("sortField", sortModel[0].field);
        params.append("sortOrder", sortModel[0].sort);
      }

      if (filterModel?.items?.length) {
        filterModel.items.forEach((filter) => {
          if (filter.operator === "isNull" || filter.operator === "isOne") {
            params.append(`filter_${filter.field}_value`, true);
            params.append(`filter_${filter.field}_operator`, filter.operator);
          } else if (filter.value !== undefined) {
            params.append(`filter_${filter.field}_value`, filter.value);
            params.append(
              `filter_${filter.field}_operator`,
              filter.operator || "equals"
            );
          }
        });
      }

      try {
        const response = await customFetch(
          `/v2/tenantadmin/${currentUser.tenant_id}/leads?${params}`,
          { method: "GET" }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const processedData = data.leads.map((item) => ({
          ...item,
          created: item.created ? parseISODateString(item.created) : null,
          dob: item.dob ? parseISODateStringNoTimezone(item.dob) : null,
          product_type: getProductName(item.product_id, item),
        }));

        setRows(processedData);
        setTotalCount(data.total);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [
      filterModel,
      sortModel,
      paginationModel,
      currentUser.tenant_id,
      getProductName,
    ]
  );

  useEffect(() => {
    debouncedFetchLeads();
    return () => debouncedFetchLeads.cancel();
  }, [debouncedFetchLeads]);

  // Update query params only if they differ
  const updateQueryParams = (params) => {
    const searchParams = new URLSearchParams(location.search);
    let changed = false;

    for (const [key, value] of Object.entries(params)) {
      const currentVal = searchParams.get(key);
      const newVal = String(value);
      if (currentVal !== newVal) {
        searchParams.set(key, newVal);
        changed = true;
      }
    }

    if (changed) {
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  };

  const onSortModelChange = (newSortModel) => {
    // Only update if there's a genuine change
    if (JSON.stringify(sortModel) !== JSON.stringify(newSortModel)) {
      setSortModel(newSortModel);
      updateQueryParams({ sort: JSON.stringify(newSortModel) });
    }
  };

  const onFilterModelChange = (newFilterModel) => {
    // Only update if there's a genuine change
    if (JSON.stringify(filterModel) !== JSON.stringify(newFilterModel)) {
      setFilterModel(newFilterModel);
      updateQueryParams({ filters: JSON.stringify(newFilterModel) });
    }
  };

  const onPaginationModelChange = (newPaginationModel) => {
    // Only update if there's a genuine change
    if (
      paginationModel.page !== newPaginationModel.page ||
      paginationModel.pageSize !== newPaginationModel.pageSize
    ) {
      setPaginationModel({
        page: newPaginationModel.page,
        pageSize:
          newPaginationModel.pageSize > 0 ? newPaginationModel.pageSize : 10,
      });

      updateQueryParams({
        page: newPaginationModel.page,
        pageSize: newPaginationModel.pageSize,
      });
    }
  };

  const customNumberOperators = getGridNumericOperators()
    .concat([
      {
        label: "Unassigned",
        value: "isNull",
        headerLabel: "Unassigned",
        getApplyFilterFn: () => (params) =>
          params.value === null ||
          params.value === undefined ||
          params.value === "",
      },
      {
        label: "Pending Bulk Assign",
        value: "isOne",
        headerLabel: "Pending Bulk Assign",
        getApplyFilterFn: () => (params) => params.value === 1,
      },
    ])
    .filter(
      (operator) =>
        operator.value !== "isEmpty" && operator.value !== "isNotEmpty"
    );

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const updateRow = (updatedLead) => {
    const parsedLead = {
      ...updatedLead,
      product_type: getProductName(updatedLead.product_id, updatedLead),
      created: updatedLead.created
        ? parseISODateString(updatedLead.created)
        : null,
      dob: updatedLead.dob
        ? parseISODateStringNoTimezone(updatedLead.dob)
        : null,
    };

    setRows((prevRows) =>
      prevRows.map((row) => (row.id === parsedLead.id ? parsedLead : row))
    );
  };

  const columns = [
    {
      field: "ref_order",
      align: "left",
      headerAlign: "left",
      type: "number",
      headerName: "Order ID",
      minWidth: 120,
      filterOperators: customNumberOperators,
      renderCell: (params) => (
        <>
          {params.row.ref_order ? (
            params.row.ref_order === 1 ? (
              <p>Pending Bulk Assign</p>
            ) : (
              params.row.ref_order
            )
          ) : (
            <>
              <p>Unassigned</p>
              {params.row.recommended && params.row.recommended.label}
            </>
          )}
        </>
      ),
    },
    {
      field: "agent_name",
      headerName: "Agent Name",
      minWidth: 200,
      valueGetter: (params, row) =>
        `${row.first_name || ""} ${row.last_name || ""}`.trim(),
    },
    { field: "name", headerName: "Lead Name", minWidth: 200 },
    {
      field: "created",
      headerName: "Created Date",
      type: "date",
      minWidth: 120,
      filterOperators: dateOperators,
    },
    { field: "state", headerName: "State", minWidth: 100 },
    {
      field: "product_type",
      minWidth: 200,
      headerName: "Product Name",
    },
    { field: "email", headerName: "Email", minWidth: 200 },
    { field: "phone", headerName: "Phone", minWidth: 140 },
    { field: "dob", headerName: "Dob", type: "date", minWidth: 100 },
    { field: "city", headerName: "City", minWidth: 120 },
    { field: "zip", headerName: "Zip", minWidth: 100 },
    {
      field: "custom_fields",
      headerName: "Custom Fields",
      minWidth: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params.row.custom_fields &&
        Object.entries(params.row.custom_fields).map(([key, value]) => (
          <div key={String(key)}>
            <strong>{String(key)}:</strong> {String(value)}
          </div>
        )),
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <EditLead
            lead={params.row}
            productsList={productsList}
            updateRow={updateRow}
          />
          <span> </span>
          <DeleteLead lead={params.row} />
          {params.row.crm_id === null && params.row.ref_order !== null && (
            <>
              <span> </span>
              <RepushLead lead={params.row} updateRow={updateRow} />
            </>
          )}
        </>
      ),
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    phone: false,
    dob: false,
    city: false,
    zip: false,
    custom_fields: true,
    email: false,
  });

  return (
    <div className="content">
      <Container maxWidth={false} sx={{ px: 2, py: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
            <Typography
              variant="h2"
              component="h2"
              gutterBottom
              sx={{
                color: "primary.main",
                marginBottom: 2,
              }}
            >
              Leads
            </Typography>
            <Box sx={{ marginBottom: 2 }}>
              <CreateProductLead productsList={productsList} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
            <Card
              sx={{
                minWidth: 300,
                maxWidth: 700,
                padding: 0,
                display: "inline-block",
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Active States
                </Typography>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  wrap="wrap"
                  sx={{ maxHeight: 64, overflow: "auto" }}
                >
                  {states.map((state, index) => (
                    <Grid item key={index}>
                      <Chip
                        key={state.state}
                        label={state.state}
                        color={
                          state.status === "inactive" ? "error" : "default"
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        
        {/* Data Grid with proper container */}
        <Paper 
          elevation={0} 
          sx={{ 
            mt: 3,
            width: '100%',
            overflow: 'hidden', // Important - prevents Paper from overflowing
          }}
        >
          <DataGridPro
            rows={rows}
            loading={loading}
            columns={columns}
            paginationModel={paginationModel}
            filterModel={filterModel}
            sortModel={sortModel}
            onPaginationModelChange={onPaginationModelChange}
            onFilterModelChange={onFilterModelChange}
            onSortModelChange={onSortModelChange}
            rowCount={totalCount}
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            initialState={{
              columns: {
                columnVisibilityModel: columnVisibilityModel,
              },
            }}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            slots={{
              toolbar: CustomToolbar,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            autoHeight
            pageSizeOptions={[10, 25, 50, 100]}
            pagination
            headerFilters
            disableColumnMenu={false}
            sx={{
              height: 'auto',
              minHeight: '600px', // Minimum height to prevent it from being too small
              width: '100%',
              border: '1px solid rgba(224, 224, 224, 1)',
              '& .MuiDataGrid-main': {
                overflow: 'auto',
              },
              '& .MuiDataGrid-virtualScroller': {
                overflow: 'auto !important',
              },
            }}
          />
        </Paper>
      </Container>
    </div>
  );
}