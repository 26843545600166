import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  IconButton,
  Chip,
  Tabs,
  Tab,
  Paper,
  Button,
  Tooltip,
  CircularProgress,
  Alert,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CampaignIcon from "@mui/icons-material/Campaign";
import LayersIcon from "@mui/icons-material/Layers";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";
import EditCampaignDialog from "./components/EditCampaignDialog";
import EditAdSetDialog from "./components/EditAdSetDialog";
import CampaignScheduleDialog from "./components/CampaignScheduleDialog";

// Set MUI X license
LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

// Custom toolbar for DataGrid
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

// Custom NoRowsOverlay for DataGrid
function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        p: 2,
      }}
    >
      <Typography variant="h6" color="text.secondary" gutterBottom>
        No data available
      </Typography>
      <Typography variant="body2" color="text.secondary">
        There are no items to display.
      </Typography>
    </Box>
  );
}

const TenantAdsManagement = () => {
  // State variables
  const [tabValue, setTabValue] = useState(0);
  const [campaigns, setCampaigns] = useState([]);
  const [adSets, setAdSets] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedAdSet, setSelectedAdSet] = useState(null);
  const [campaignDialogOpen, setCampaignDialogOpen] = useState(false);
  const [adSetDialogOpen, setAdSetDialogOpen] = useState(false);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [dataGridReady, setDataGridReady] = useState(false);
  const [pendingFilter, setPendingFilter] = useState(null);
  const currentUser = decode();

  // Fetch campaigns
  const fetchCampaigns = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/campaigns`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch campaigns");
      }
      const data = await response.json();
      setCampaigns(data);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
      setError("Failed to load campaigns. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch all ad sets
  const fetchAllAdSets = async (campaignFilter = null) => {
    setLoading(true);
    setError(null);
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ad_sets`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch ad sets");
      }
      const data = await response.json();
      setAdSets(data);

      // If a campaign filter was provided, set it as pending
      if (campaignFilter) {
        // Only set the campaign filter, preserving other filters
        setPendingFilter({
          items: [
            {
              field: "campaign_id",
              operator: "=",
              value: campaignFilter,
            },
          ],
        });
      }
    } catch (error) {
      console.error("Error fetching ad sets:", error);
      setError("Failed to load ad sets. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Load data based on active tab
  useEffect(() => {
    if (tabValue === 0) {
      // Clear filters before fetching campaigns to avoid filter errors
      setFilterModel({
        items: [],
      });
      fetchCampaigns();
    } else {
      // Only fetch ad sets without clearing filters
      fetchAllAdSets();
    }
  }, [tabValue]);

  // Apply pending filter when DataGrid is ready and data is loaded
  useEffect(() => {
    if (dataGridReady && !loading && pendingFilter && tabValue === 1) {
      // When applying a campaign filter, we want to replace any existing campaign_id filter
      // but preserve all other filters
      const existingFilters = filterModel.items.filter(item => item.field !== "campaign_id");
      
      // Combine existing non-campaign filters with the new campaign filter
      setFilterModel({
        items: [...existingFilters, ...pendingFilter.items],
      });
      
      // Clear pending filter after applying
      setPendingFilter(null);
    }
  }, [dataGridReady, loading, pendingFilter, tabValue]);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    // Reset dataGridReady state when changing tabs
    setDataGridReady(false);
    
    // When switching to campaigns tab, clear filters to avoid filter errors
    if (newValue === 0) {
      // Clear all filters when switching to campaigns tab
      setFilterModel({
        items: [],
      });
      setSelectedCampaign(null);
    }
    
    // Add a small delay before changing tabs to ensure filter clearing is processed
    setTimeout(() => {
      // Change the tab
      setTabValue(newValue);
    }, 50);
  };

  // Handle campaign edit click
  const handleEditCampaignClick = (campaign) => {
    setSelectedCampaign(campaign);
    setCampaignDialogOpen(true);
  };

  // Handle ad set edit click
  const handleEditAdSetClick = (adSet) => {
    setSelectedAdSet(adSet);
    setAdSetDialogOpen(true);
  };

  // Handle schedule click
  const handleScheduleClick = (campaign) => {
    setSelectedCampaign(campaign);
    setScheduleDialogOpen(true);
  };

  // Handle campaign dialog close
  const handleCampaignDialogClose = () => {
    setCampaignDialogOpen(false);
    setSelectedCampaign(null);
  };

  // Handle ad set dialog close
  const handleAdSetDialogClose = () => {
    setAdSetDialogOpen(false);
    setSelectedAdSet(null);
  };

  // Handle schedule dialog close
  const handleScheduleDialogClose = () => {
    setScheduleDialogOpen(false);
  };

  // Handle campaign edit
  const handleCampaignEdit = async (id, updatedCampaign) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/campaigns/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedCampaign),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update campaign");
      }
      fetchCampaigns(); // Refresh campaigns after update
    } catch (error) {
      console.error("Error updating campaign:", error);
      setError("Failed to update campaign. Please try again.");
    }
  };

  // Handle ad set edit
  const handleAdSetEdit = async (id, updatedAdSet) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ad_sets/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedAdSet),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update ad set");
      }

      // Always fetch all ad sets after an update
      fetchAllAdSets();
    } catch (error) {
      console.error("Error updating ad set:", error);
      setError("Failed to update ad set. Please try again.");
    }
  };

  // Handle view ad sets for campaign
  const handleViewAdSets = (campaign) => {
    if (!campaign || !campaign.id) {
      console.error("Invalid campaign object:", campaign);
      setError("Cannot view ad sets: Invalid campaign data");
      return;
    }

    setSelectedCampaign(campaign);

    // Reset dataGridReady state
    setDataGridReady(false);

    // Switch to ad sets tab
    setTabValue(1);

    // Fetch ad sets with the campaign filter
    fetchAllAdSets(campaign.id);
  };

  // Format currency
  const formatCurrency = (value, currency = "USD") => {
    if (value == null) return "";
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    }).format(value);
  };

  // Add a function to handle filter model changes with error handling
  const handleFilterModelChange = (newModel) => {
    try {
      console.log("New filter model:", newModel);
      setFilterModel(newModel);
    } catch (error) {
      console.error("Error applying filter model:", error);
      // Reset to empty filter model on error
      setFilterModel({ items: [] });
    }
  };

  // Campaign columns for DataGrid
  const campaignColumns = [
    {
      field: "id",
      headerName: "Campaign ID",
      type: "number",
      width: 100,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "campaign_name",
      headerName: "FB Campaign Name",
      width: 250,
      renderCell: (params) => {
        const hasCustomName =
          params.row.custom_name && params.row.custom_name !== "";
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {hasCustomName ? (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    color: "primary.main",
                    lineHeight: 1.2,
                  }}
                >
                  {params.row.custom_name}
                </Typography>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ fontSize: "0.75rem", lineHeight: 1.2 }}
                >
                  {params.row.campaign_name}
                </Typography>
              </>
            ) : (
              <Typography variant="body2">
                {params.row.campaign_name}
              </Typography>
            )}
          </Box>
        );
      },
    },
    { field: "campaign_id", headerName: "FB Campaign ID", width: 150 },
    {
      field: "isactive",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Chip
          label={params.value ? "Active" : "Inactive"}
          color={params.value ? "success" : "default"}
          variant={params.value ? "filled" : "outlined"}
          size="small"
        />
      ),
    },
    {
      field: "is_automated",
      headerName: "Automation",
      width: 130,
      renderCell: (params) => (
        <Chip
          icon={params.value ? <AutoFixHighIcon /> : null}
          label={params.value ? "Enabled" : "Disabled"}
          color={params.value ? "success" : "default"}
          variant={params.value ? "filled" : "outlined"}
          size="small"
        />
      ),
    },
    {
      field: "schedule",
      headerName: "Schedule",
      width: 150,
      renderCell: (params) => (
        <Box>
          {params.row.schedule ? (
            <Tooltip
              title={`${params.row.schedule.start_time} - ${
                params.row.schedule.end_time
              } (${params.row.schedule.time_zone || "UTC"})`}
            >
              <Chip
                icon={<ScheduleIcon />}
                label={`${params.row.schedule.start_time.substring(
                  0,
                  5
                )} - ${params.row.schedule.end_time.substring(0, 5)}`}
                color="info"
                size="small"
              />
            </Tooltip>
          ) : (
            <Tooltip title="No schedule configured for this campaign">
              <Chip label="No Schedule" variant="outlined" size="small" />
            </Tooltip>
          )}
        </Box>
      ),
    },
    { field: "integration_name", headerName: "Integration", width: 150 },
    { field: "objective", headerName: "Objective", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            justifyContent: "left",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Tooltip title="Edit Campaign">
            <IconButton
              color="primary"
              onClick={() => handleEditCampaignClick(params.row)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Manage Schedule">
            <IconButton
              color="info"
              onClick={() => handleScheduleClick(params.row)}
            >
              <ScheduleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Ad Sets">
            <Button
              variant="outlined"
              size="small"
              startIcon={<LayersIcon />}
              onClick={() => handleViewAdSets(params.row)}
            >
              Ad Sets
            </Button>
          </Tooltip>
        </Box>
      ),
    },
  ];

  // Ad Set columns for DataGrid
  const adSetColumns = [
    {
      field: "id",
      headerName: "Ad Set ID",
      type: "number",
      width: 100,
      renderCell: (params) => <div>{params.value}</div>,
    },
    { field: "ad_set_id", headerName: "FB Ad Set ID", width: 150 },
    {
      field: "campaign_id",
      headerName: "Campaign ID",
      type: "number",
      width: 100,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "name",
      headerName: "Ad Set Name",
      width: 250,
      renderCell: (params) => {
        const hasCustomName =
          params.row.custom_name && params.row.custom_name !== "";
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {hasCustomName ? (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    color: "primary.main",
                    lineHeight: 1.2,
                  }}
                >
                  {params.row.custom_name}
                </Typography>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ fontSize: "0.75rem", lineHeight: 1.2 }}
                >
                  {params.row.name}
                </Typography>
              </>
            ) : (
              <Typography variant="body2">
                {params.row.name || "Unnamed Ad Set"}
              </Typography>
            )}
          </Box>
        );
      },
    },
    { field: "status", headerName: "Status", width: 100 },
    {
      field: "cost_per_result",
      headerName: "Cost Per Result",
      width: 120,
      type: "number",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          <Typography variant="body2">
            {formatCurrency(params.value, params.row.currency)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "amount_spent",
      headerName: "Amount Spent",
      width: 120,
      type: "number",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          <Typography variant="body2">
            {formatCurrency(params.value, params.row.currency)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "cost_per_result_threshold",
      headerName: "CPR Threshold",
      width: 120,
      type: "number",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          <Typography variant="body2">
            {formatCurrency(params.value, params.row.currency)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "amount_spent_threshold",
      headerName: "Spend Threshold",
      width: 130,
      type: "number",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          <Typography variant="body2">
            {formatCurrency(params.value, params.row.currency)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "is_automated",
      headerName: "Automation",
      width: 120,
      renderCell: (params) => (
        <Chip
          icon={params.value ? <AutoFixHighIcon /> : null}
          label={params.value ? "Enabled" : "Disabled"}
          color={params.value ? "success" : "default"}
          variant={params.value ? "filled" : "outlined"}
          size="small"
        />
      ),
    },
    {
      field: "force_enable",
      headerName: "Force Enable",
      width: 110,
      renderCell: (params) => (
        <Chip
          label={params.value ? "Yes" : "No"}
          color={params.value ? "success" : "default"}
          variant={params.value ? "filled" : "outlined"}
          size="small"
        />
      ),
    },
    {
      field: "force_disable",
      headerName: "Force Disable",
      width: 110,
      renderCell: (params) => (
        <Chip
          label={params.value ? "Yes" : "No"}
          color={params.value ? "error" : "default"}
          variant={params.value ? "filled" : "outlined"}
          size="small"
        />
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => handleEditAdSetClick(params.row)}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div className="content">
      <Container
        maxWidth={false}
        disableGutters
        sx={{ width: "100%", padding: 0, margin: 0 }}
      >
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main",
            marginBottom: 2,
            textAlign: "left",
          }}
        >
          Ads Management
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Paper sx={{ mb: 3 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              icon={<CampaignIcon />}
              label="Campaigns"
              iconPosition="start"
            />
            <Tab
              icon={<LayersIcon />}
              label={
                selectedCampaign
                  ? `Ad Sets: ${
                      selectedCampaign.custom_name ||
                      selectedCampaign.campaign_name ||
                      selectedCampaign.name ||
                      "Campaign"
                    }`
                  : "All Ad Sets"
              }
              iconPosition="start"
            />
          </Tabs>
        </Paper>

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ height: 650, width: "100%" }}>
            {tabValue === 0 ? (
              <DataGridPro
                rows={campaigns}
                columns={campaignColumns}
                getRowId={(row) => row.id}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10 },
                  },
                  sorting: {
                    sortModel: [{ field: "id", sort: "desc" }],
                  },
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                headerFilters
                slots={{
                  toolbar: CustomToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            ) : (
              <DataGridPro
                rows={adSets}
                columns={adSetColumns}
                getRowId={(row) => row.id}
                filterModel={filterModel}
                onFilterModelChange={handleFilterModelChange}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10 },
                  },
                  sorting: {
                    sortModel: [{ field: "id", sort: "desc" }],
                  },
                  columns: {
                    columnVisibilityModel: {
                      custom_name: false,
                    },
                  },
                }}
                headerFilters
                pageSizeOptions={[5, 10, 25, 50]}
                slots={{
                  toolbar: CustomToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                keepNonTabularColumnState
                onStateChange={(params) => {
                  // Mark DataGrid as ready after initial state is set
                  if (!dataGridReady) {
                    setDataGridReady(true);
                  }
                }}
              />
            )}
          </Box>
        )}

        {/* Campaign Edit Dialog */}
        {selectedCampaign && (
          <EditCampaignDialog
            open={campaignDialogOpen}
            onClose={handleCampaignDialogClose}
            campaign={selectedCampaign}
            onEdit={handleCampaignEdit}
            tenantId={currentUser.tenant_id}
          />
        )}

        {/* Ad Set Edit Dialog */}
        {selectedAdSet && (
          <EditAdSetDialog
            open={adSetDialogOpen}
            onClose={handleAdSetDialogClose}
            adSet={selectedAdSet}
            onEdit={handleAdSetEdit}
          />
        )}

        {/* Campaign Schedule Dialog */}
        {selectedCampaign && (
          <CampaignScheduleDialog
            open={scheduleDialogOpen}
            onClose={handleScheduleDialogClose}
            campaign={selectedCampaign}
            tenantId={currentUser.tenant_id}
            onScheduleUpdated={fetchCampaigns}
          />
        )}
      </Container>
    </div>
  );
};

export default TenantAdsManagement;
